<template>
  <div class="root">
    <div class="title">
      <p>预约单</p>
    </div>
    <div class="outer">
      <van-form v-if="Object.keys(bookInfo).length > 0">
        <van-field v-model="bookInfo.resvNo" label="预约单号" readonly />
        <van-field v-model="status" label="预约状态" readonly />
        <van-field v-model="bookInfo.packageName" label="套餐" readonly />
        <van-field v-model="bookInfo.institutionName" label="预约机构" readonly />
        <van-field v-model="resvDate" label="预约时间" readonly />
        <van-field v-model="bookInfo.requestTimeSpan" label="时间段" readonly />
        <van-field v-model="bookInfo.cardNo" label="卡号" readonly />
        <van-field v-model="bookInfo.userInfo.name" label="体检人姓名" readonly />
        <van-field v-model="bookInfo.userInfo.address" label="体检人地址" readonly />
        <van-field v-model="sex" label="体检人性别" readonly />
      </van-form>
    </div>
  </div>
</template>
<script>
import { Popup, Notify, Dialog } from 'vant';
import { MedicalInfo } from "@/api/richApi";
import { getMenu } from "@/api/api";
export default {
  data() {
    return {
      CheckCardNo: '',
      bookInfo: {},
    };
  },
  async mounted() {
    await this.getCheckCardNo();
    await this.getBookForm();
  },
  computed: {
    resvDate() {
      if (Object.keys(this.bookInfo).length > 0) {
        return this.bookInfo.resvDate.slice(0, 10)
      }
    },
    status() {
      if (this.bookInfo.status == 0) {
        return '取消预约'
      } else if (this.bookInfo.status == 1 || this.bookInfo.status == 1) {
        return '已预约'
      } else {
        return '已到检'
      }
    },
    sex() {
      return this.bookInfo.sex == 0 ? '男' : '女'
    }
  },
  methods: {
    getCheckCardNo() {
      this.CheckCardNo = this.$route.query.CheckCardNo
    },
    async getBookForm() {
      let res = await MedicalInfo({
        CheckCardNo: this.CheckCardNo
      })
      if (res.data.code == '00000') {
        this.bookInfo = res.data.data
      } else {
        Notify({ type: "warning", message: res.data.msg });
      }
    },
  },
};
</script>
<style lang="less" scoped>
*{
  margin: 0;
}
.root {
  background: #F1F4FB 100% 100%;
  height: 100vh;

  .title {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      text-align: center;
      font-size: 24px;
      color: rgb(51, 51, 51);
      font-weight: bold;
    }
  }
}
</style>