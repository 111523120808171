<template>
  <!-- 报告解读 -->
  <van-form validate-first>
    <div class="home">
      <div class="center">
        <div class="formBox">
          <h3 class="title">报告解读</h3>
          <van-field
            readonly
            clickable
            required
            name="customerName"
            :value="form.customerName"
            label="为谁咨询"
            placeholder="请选择为谁咨询"
            right-icon="arrow"
            @click="nameCheck"
          />
          <van-popup v-model="nameShow" position="bottom">
            <van-picker
              show-toolbar
              :columns="nameSelect"
              @confirm="nameConfirm"
              @cancel="nameShow = false"
            >
              <template #option="option">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <div>{{ option.name }}</div>
                </div>
              </template>
            </van-picker>
          </van-popup>
          <van-field
            v-model="form.consultInfo"
            rows="2"
            :autosize="{ maxHeight: 100, minHeight: 100 }"
            label="描述病情"
            type="textarea"
            maxlength="150"
            placeholder="请具体描述您的病情"
            show-word-limit
          />
        </div>
        <div class="formBox">
          <h3 class="title">检查报告照片</h3>
          <div style="margin: 10px">
            点击可查看原图
            <span style="color: #3e77db">{{ imageNum }}/9</span>
          </div>
          <van-uploader
            style="padding: 10px"
            v-model="fileList"
            :after-read="clzpAfterRead"
            @delete="deleteClzp"
            :max-count="9"
            multiple
          >
          </van-uploader>
        </div>
      </div>

      <div class="footer">
        <div>
          <van-button
            @click="submitBtn"
            :loading="loading"
            :disabled="loading"
            type="info"
            color="#3e77db"
            round
            block
            style="border-radius: 30px; padding: 0 40px"
            >提交</van-button
          >
        </div>
      </div>
    </div>
    <!-- 确认弹窗 -->
    <van-dialog
      v-model="showDialog"
      :message="dialogContent"
      confirm-button-color="#3e77db"
      :showCancelButton="true"
      @confirm="handleConfirm"
      @cancel="handleCancel"
    >
    </van-dialog>
  </van-form>
</template>
<script>
import { getDictAnother, reportInterpretation, checkConsult } from "@/api/api";
import { Notify } from "vant";
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  data() {
    return {
      loading: false,
      showDialog: false,
      dialogContent: "",
      form: {
        consultCustomerId: "",
        customerName: "",
        consultInfo: "",
      },
      fileList: [], //上传的文件列表
      clzpfilePath: "", //删除所需参数
      productCode: "",
      imageNum: 0,
      nameSelect: [],
      nameShow: false,
      visitTimeShow: false,
    };
  },
  mounted() {
    this.productCode = this.$route.query.productCode;
    getDictAnother().then((res) => {
      this.nameSelect = res.data.data.customerList;
    });
  },
  methods: {
    nameCheck() {
      if (this.nameSelect == "") {
        this.$router.push({ name: "mine/familyInfo" });
      } else {
        this.nameShow = true;
      }
    },
    nameConfirm(value) {
      if (value.disabled == false) {
        this.form.customerName = value.name;
        this.form.consultCustomerId = value.id;
        this.nameShow = false;
      }
    },
    //照片上传事件方法
    clzpAfterRead(file) {
      this.imageNum = this.fileList.length;
    },
    //照片删除事件方法
    deleteClzp() {
      this.imageNum = this.fileList.length;
    },
    submitBtn() {
      if (this.form.customerName == "") {
        Toast("请选择为谁咨询");
      } else {
        this.loading = true;
        checkConsult({ productCode: this.productCode }).then((res) => {
          if (res.data.code == "00000") {
            this.showDialog = true;
            this.dialogContent = res.data.msg;
          } else if (res.data.code == "00001") {
            Notify({ type: "danger", message: res.data.msg });
            this.loading = false;
            setTimeout(() => {
              window.location.href =
                res.data.data.productUrl +
                "?productCode=" +
                res.data.data.productCode +
                "&token=" +
                localStorage.getItem("token");
            }, 800);
          }
        });
      }
    },
    handleConfirm() {
      // 处理确认按钮点击事件
      reportInterpretation({
        dataInfo: this.form,
        fileList: this.fileList,
      }).then((res) => {
        if (res.data.code == "00000") {
          Notify({ type: "success", message: "提交成功" });
          this.loading = false;
          setTimeout(() => {
            this.$router.go(-1);
          }, 300);
        } else {
          Notify({ type: "danger", message: res.data.msg });
          this.loading = false;
        }
      });
    },
    handleCancel() {
      // 处理取消按钮点击事件
      this.loading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .center {
    padding: 15px;
    box-sizing: border-box;
    flex: 1;
    overflow: auto;
  }
  .footer {
    width: 100%;
    height: 65px;
    padding: 0 10px;
    box-sizing: border-box;
  }
}
.title {
  padding: 0 10px;
  box-sizing: border-box;
}
.formBox {
  margin: 10px 0;
  border-radius: 10px;
  background: #fff;
  padding: 5px;
  box-sizing: border-box;
}
</style>