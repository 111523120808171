import { render, staticRenderFns } from "./healthInfo.vue?vue&type=template&id=89b8f612&scoped=true&"
import script from "./healthInfo.vue?vue&type=script&lang=js&"
export * from "./healthInfo.vue?vue&type=script&lang=js&"
import style0 from "./healthInfo.vue?vue&type=style&index=0&id=89b8f612&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89b8f612",
  null
  
)

export default component.exports