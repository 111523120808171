export default {
  namespaced: true,
  state: {
    channelCode:''
  },
  actions: {},
  mutations: {
    //保存channelCode
    saveChannelCode(state, value) {
      state.channelCode = value
    },
  },
  getters: {
  }
}
