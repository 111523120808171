<template>
  <!-- 健康档案历史记录 -->
  <div class="container">
    <div style="flex: 1; overflow: auto">
      <van-swipe-cell
        v-for="(item, index) in historyInfo"
        :key="index"
        :before-close="beforeClose"
        :dataInfo="item"
        style="margin: 15px 0; border-radius: 10px"
      >
        <van-cell :border="false" @click="editBtn(item)">
          <div class="display" style="height: 60px">
            <div>
              <div
                style="font-size: 16px; font-weight: bold; margin-bottom: 5px"
              >
                {{ item.name }}
              </div>
              <div>
                {{ item.gender }}
                <span style="margin-left: 10px">{{ item.age }}岁</span>
              </div>
            </div>
            <div>
              <div>就诊时间：{{ item.visitTime }}</div>
              <div v-if="item.recordType == 1">门诊历史记录</div>
              <div v-if="item.recordType == 2">购药历史记录</div>
              <div v-if="item.recordType == 3">体检历史记录</div>
            </div>
            <div>
              <van-tag type="primary">{{ item.relationType }}</van-tag>
            </div>
          </div>
        </van-cell>
        <template #right>
          <van-button square type="danger" text="删除" style="height: 80px" />
        </template>
      </van-swipe-cell>
    </div>
  </div>
</template>
  <script>
import { Notify } from "vant";
import { Dialog } from "vant";
import { historyInfo, historyInfoDelete } from "@/api/api";
export default {
  data() {
    return {
      historyInfo: [],
    };
  },
  mounted() {
    const recordType = location.href.split("recordType=")[1];
    historyInfo({ recordType: recordType }).then((res) => {
      this.historyInfo = res.data.data;
      if (res.data.data == "") {
        Notify({ type: "primary", message: "暂无历史数据" });
      }
    });
  },
  methods: {
    beforeClose({ position, instance }) {
      switch (position) {
        case "right":
          Dialog.confirm({
            message: "确定删除吗？",
          }).then(() => {
            const recordType = location.href.split("recordType=")[1];
            historyInfoDelete({ id: instance.$attrs.dataInfo.id }).then(
              (res) => {
                if (res.data.code == "00000") {
                  Notify({ type: "success", message: "删除成功" });
                  historyInfo({ recordType: recordType }).then((res) => {
                    this.historyInfo = res.data.data;
                  });
                } else {
                  Notify({ type: "danger", message: "删除失败" });
                }
              }
            );
          });
          break;
      }
    },
    //点击单个
    editBtn(item) {
      this.$router.push({
        name: "mine/healthRecords/historyInfo/historyInformation",
        query: { id: item.id },
      });
    },
  },
};
</script>
  <style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0px;
}

.display {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>