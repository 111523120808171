<template>
  <div class="root">
    <!-- <iframe :src="policyUrl" style="width: 100vw; height: 100vh;" frameborder="0"  v-if="fileType == 'pdf'"></iframe> -->
    <img :src="policyUrl" style="width: 100vw;">
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import { getWxSign } from "@/api/api";
import { init } from "echarts";
export default {
  data() {
    return {
      policyUrl: '',
      fileType: '',
      nowDate: '',
    }
  },
  methods: {
    handleLaunchFn(e) {
      console.log('success', e)
    },
    handleErrorFn(e) {
      console.log('fail', e.detail);
    },
    weixinConfig() {
      // let url = location.href.split('#')[0]
      let url = window.location.href
      console.log(url);
      getWxSign({ url }).then(res => {
        //console.log(JSON.stringify(res))
        wx.config({
          debug: false,
          appId: 'wxdd96d3663f62e8ed',
          timestamp: res.data.timestamp,
          nonceStr: res.data.nonceStr,
          signature: res.data.signature,
          jsApiList: [
            "hideMenuItems"
          ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          openTagList: ['wx-open-launch-weapp']
        });
        wx.ready(function (res) {
          wx.hideMenuItems({
            menuList: [
              "menuItem:share:appMessage",
              "menuItem:share:timeline",
              "menuItem:share:qq",
              "menuItem:favorite",
              "menuItem:share:weiboApp",
              "menuItem:share:QZone",
              "menuItem:copyUrl",
              "menuItem:openWithQQBrowser",
              "menuItem:openWithSafari",
              "menuItem:share:email",
            ]
          });
        });
        // location.reload()
        wx.error(function () {
          console.log('config error');
        })
      })
    },
  },
  mounted() {
    setTimeout(() => {
      if (location.href.indexOf("#reloaded") == -1) {
        location.href = location.href + "#reloaded";
        location.reload();
      }
    }, 300)
    this.policyUrl = this.$route.query.policyUrl
    this.fileType = this.$route.query.fileType
    this.nowDate = this.$route.query.nowDate
    this.weixinConfig();
  },
}
</script>
<style lang="less" scoped></style>