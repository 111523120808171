import request from "@/utils/request";

// 查询卡片信息
export function getCardInfoByToken(data) {
    return request({
        url: '/merge/RuiCiBusiness/getCardInfoByToken',
        method: 'post',
        data
    })
}
// 查询机构信息（也可根据套餐获取符合条件的机构信息）
export function getMedicalInstitutionList(data) {
  return request({
      url: '/merge/RuiCiBusiness/GetMedicalInstitutionList',
      method: 'post',
      data
  })
}
// 用户登陆并获取虚卡及套餐接口
export function LoginAndGetVCardInfo(data) {
  return request({
      url: '/merge/RuiCiBusiness/LoginAndGetVCardInfo',
      method: 'post',
      data
  })
}
// 查询体检人员信息
export function QueryUserInfo(data) {
  return request({
      url: '/merge/RuiCiBusiness/QueryUserInfo',
      method: 'post',
      data
  })
}
// 根据userId查询预约信息(人员)
export function QueryUserInfoByUserId(data) {
  return request({
      url: '/merge/RuiCiBusiness/QueryUserInfoByUserId',
      method: 'post',
      data
  })
}
// 删除体检人员信息
export function DelUserInfo(data) {
  return request({
      url: '/merge/RuiCiBusiness/DelUserInfo',
      method: 'post',
      data
  })
}
// 新增体检人员信息
export function AddUserInfo(data) {
  return request({
      url: '/merge/RuiCiBusiness/AddUserInfo',
      method: 'post',
      data
  })
}
// 修改预约信息(人员)
export function UpdateUserInfo(data) {
  return request({
      url: '/merge/RuiCiBusiness/UpdateUserInfo',
      method: 'post',
      data
  })
}
// 根据卡片id获取卡片信息
export function getCardInfoById(data) {
  return request({
      url: '/merge/RuiCiBusiness/getCardInfoById',
      method: 'post',
      data
  })
}
// 获取机构容量接口
export function GetCapacityOfInstitution(data) {
  return request({
      url: '/merge/RuiCiBusiness/GetCapacityOfInstitution',
      method: 'post',
      data
  })
}
//预约
export function MedicalBooking(data) {
  return request({
      url: '/merge/RuiCiBusiness/MedicalBooking',
      method: 'post',
      data
  })
}
//取消预约
export function CancelBook(data) {
  return request({
      url: '/merge/RuiCiBusiness/cancelBook',
      method: 'post',
      data
  })
}
//修改预约
export function ChangeMedicalBooking(data) {
  return request({
      url: '/merge/RuiCiBusiness/ChangeMedicalBooking',
      method: 'post',
      data
  })
}
//根据PackageId获取机构列表并组装为text-value结构
export function getInsListPackageId(data) {
  return request({
      url: '/merge/RuiCiBusiness/getInsListPackageId',
      method: 'post',
      data
  })
}
//预约单信息查询
export function MedicalInfo(data) {
  return request({
      url: '/merge/RuiCiBusiness/MedicalInfo',
      method: 'post',
      data
  })
}
//查询报告状态
export function GetReportInfo(data) {
  return request({
      url: '/merge/RuiCiBusiness/GetReportInfo',
      method: 'post',
      data
  })
}
//获取报告接口
export function DownloadReport(data) {
  return request({
      url: '/merge/RuiCiBusiness/DownloadReport',
      method: 'post',
      data
  })
}
//查询报告列表
export function queryReport(data) {
  return request({
      url: '/merge/RuiCiBusiness/queryReport',
      method: 'post',
      data
  })
}
//查询是否有体检人
export function queryCheckUserExist(data) {
  return request({
      url: '/merge/RuiCiBusiness/queryCheckUserExist',
      method: 'post',
      data
  })
}