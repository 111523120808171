<template>
  <div class="product">
    <van-search
      v-model="searchValue"
      show-action
      shape="round"
      placeholder="健管服务/保险"
      background="#f1f1f1"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <van-tabs v-model="active" color="#3e77db" sticky @click="tabsBtn" >
      <van-tab :title="type.label" :name="type.value" style="padding: 0 10px" v-for="(type,index) in productTypeList" :key="index">
        <div
          class="centerBox"
          v-for="(item, index) in productData"
          :key="index"
          @click="productBtn(item)"
        >
          <div style="width: 28%">
            <img
              :src="item.productPicture"
              alt=""
              style="width: 100%; height: auto; display: block"
            />
          </div>
          <div style="flex: 1; margin-left: 1rem">
            <ul style="font-weight: bold">
              <li>{{ item.productName }}</li>
              <li style="margin: 10px 0" v-if="item.productType == '0'">
                <van-tag
                  type="primary"
                  size="medium"
                  color="#fbf5ce"
                  text-color="#f0c830"
                  >{{ item.typeName }}</van-tag
                >
              </li>
              <li style="margin: 10px 0" v-if="item.productType == '1'">
                <van-tag
                  type="primary"
                  size="medium"
                  color="#eefdd2"
                  text-color="#9ebc48"
                  >{{ item.typeName }}</van-tag
                >
              </li>
              <li style="margin: 10px 0" v-if="item.productType == '2'">
                <van-tag
                  type="primary"
                  size="medium"
                  color="#fddede"
                  text-color="#f43b3b"
                  >{{ item.typeName }}</van-tag
                >
              </li>
              <li style="margin: 10px 0" v-if="item.productType == '3'">
                <van-tag
                  type="primary"
                  size="medium"
                  color="#e0f0fd"
                  text-color="#5288f5"
                  >{{ item.typeName }}</van-tag
                >
              </li>
              <li style="margin: 10px 0" v-if="item.productType == '4'">
                <van-tag
                  type="primary"
                  size="medium"
                  color="#fdeff9"
                  text-color="#e77dc6"
                  >{{ item.typeName }}</van-tag
                >
              </li>
              <li style="margin: 10px 0" v-if="item.productType == '5'">
                <van-tag
                  type="primary"
                  size="medium"
                  color=rgb(253,211,181)
                  text-color=rgb(253,142,19)
                >{{ item.typeName }}</van-tag
              >
              </li>
              <li style="margin: 10px 0" v-if="item.productType == '998'">
                <van-tag
                  type="primary"
                  size="medium"
                  color="#e0f0fd"
                  text-color="#5288f5"
                >{{ item.typeName }}</van-tag
              >
              </li>
            </ul>
          </div>
          <div style="text-align: right">
            <p style="color: #3e77db">详情</p>
            <p style="color: red; font-weight: bold" v-if="item.premium">
              ￥<span style="font-size: 16px">{{ item.premium }}</span>
            </p>
          </div>
        </div>
      </van-tab>
      <!-- <van-tab title="保险" style="padding: 0 10px">
        <div
          class="centerBox"
          v-for="(item, index) in productData"
          :key="index"
          @click="productBtn(item)"
        >
          <div style="width: 28%">
            <img
              :src="item.productPicture"
              alt=""
              style="width: 100%; height: auto; display: block"
            />
          </div>
          <div style="flex: 1; margin-left: 1rem">
            <ul style="font-weight: bold">
              <li>{{ item.productName }}</li>
              <li style="margin: 10px 0" v-if="item.productType == '0'">
                <van-tag
                  type="primary"
                  size="medium"
                  color="#fbf5ce"
                  text-color="#f0c830"
                  >{{ item.typeName }}</van-tag
                >
              </li>
              <li style="margin: 10px 0" v-if="item.productType == '1'">
                <van-tag
                  type="primary"
                  size="medium"
                  color="#eefdd2"
                  text-color="#9ebc48"
                  >{{ item.typeName }}</van-tag
                >
              </li>
              <li style="margin: 10px 0" v-if="item.productType == '2'">
                <van-tag
                  type="primary"
                  size="medium"
                  color="#fddede"
                  text-color="#f43b3b"
                  >{{ item.typeName }}</van-tag
                >
              </li>
              <li style="margin: 10px 0" v-if="item.productType == '3'">
                <van-tag
                  type="primary"
                  size="medium"
                  color="#e0f0fd"
                  text-color="#5288f5"
                  >{{ item.typeName }}</van-tag
                >
              </li>
              <li style="margin: 10px 0" v-if="item.productType == '4'">
                <van-tag
                  type="primary"
                  size="medium"
                  color="#fdeff9"
                  text-color="#e77dc6"
                  >{{ item.typeName }}</van-tag
                >
              </li>
            </ul>
          </div>
          <div style="text-align: right">
            <p style="color: #3e77db">详情</p>
            <p style="color: red; font-weight: bold" v-if="item.premium">
              ￥<span style="font-size: 16px">{{ item.premium }}</span>
            </p>
          </div>
        </div>
      </van-tab> -->
    </van-tabs>
    <div style="height: 4rem"></div>
  </div>
</template>
<script>
import { getProduct,getProductType } from "@/api/api";
import { Notify } from "vant";
export default {
  data() {
    return {
      searchValue: "",
      active: 0,
      productData: [],
      productTypeList:[]
    };
  },
  mounted() {
    getProduct({ queryType: 0 }).then((res) => {
      this.productData = res.data.data;
      console.log(this.productData);
    });
    getProductType({}).then((res)=>{
      this.productTypeList = res.data.data
      console.log(this.productTypeList);
    })
  },
  methods: {
    //搜索
    onSearch() {
      console.log(this.searchValue);
    },
    //tabs切换
    tabsBtn(name, title) {
      getProduct({ queryType: name }).then((res) => {
        this.productData = res.data.data;
      });
    },
    //点击产品
    productBtn(item) {
      console.log(item.productUrl);
      if (localStorage.getItem("token")) {
        window.location.href =
          item.productUrl +
          "?productCode=" +
          item.productCode +
          "&token=" +
          localStorage.getItem("token");
      } else {
        Notify({ type: "warning", message: "请先登录" });
        setTimeout(() => {
          this.$router.push({ name: "login" });
        }, 800);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.product {
  width: 100%;
  height: 100%;
  font-size: 16px;
}
/deep/.van-tab {
  background: #f1f1f1;
  color: #3e77db;
}
.centerBox {
  background: #fff;
  height: 70px;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>