<template>
  <div class="login">
    <div class="form">
      <div class="display">
        <div style="width: 20%">
          <img
            src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AFlogo1.png"
            alt
          />
        </div>
        <h3 style="font-size: 19px">您好，欢迎登陆中弘润景</h3>
      </div>
      <div style="margin-top: 2rem">
        <van-cell-group>
          <van-field
            v-model="phone"
            left-icon="phone-o"
            placeholder="请输入手机号"
            :error-message="this.phoneMsg"
            @blur="phoneBlur"
          />
          <van-field
            v-model="message"
            center
            clearable
            left-icon="chat-o"
            :error-message="this.messageMsg"
            @blur="messageBlur"
            placeholder="请输入短信验证码"
          >
            <template #button>
              <van-button
                size="small"
                type="info"
                :disabled="isDis"
                @click="messageBtn"
              >获取验证码{{ timeOver }}</van-button>
            </template>
          </van-field>
        </van-cell-group>
      </div>
      <div style="margin: 1rem">
        <van-checkbox shape="square" v-model="radio" icon-size="16px">
          <div style="margin-left: 10px;">
            我已阅读并同意
            <a style="color: #3e77db" @click="agreeBtn">《用户协议》</a>
            <a style="color: #3e77db" @click="agree1Btn">《用户隐私协议》</a>
          </div>
        </van-checkbox>
      </div>
      <van-button
        type="info"
        @click="loginBtn"
        round
        block
        style="margin-top: 2rem"
        :disabled="isTrue"
        :loading="isTrue"
      >登录</van-button>
    </div>
  </div>
</template>
<script>
import { Notify } from "vant";
import { getMassge, login } from "@/api/api";
export default {
  data() {
    return {
      phone: "",
      message: "",
      radio: false,
      phoneMsg: "",
      messageMsg: "",
      isDis: false,
      isTrue: false,
      timeOver: ""
    };
  },
  methods: {
    phoneBlur() {
      const phoneCheck = /^1[3-9][0-9]{9}$/;
      if (phoneCheck.test(this.phone) == false) {
        this.phoneMsg = "请输入正确的手机号";
      } else {
        this.phoneMsg = "";
      }
    },
    messageBlur() {
      const messageCheck = /^[0-9]\d{5}$/;
      if (messageCheck.test(this.message) == false) {
        this.messageMsg = "请输入六位验证码";
      } else {
        this.messageMsg = "";
      }
    },
    messageBtn() {
      const phoneCheck = /^1[3-9][0-9]{9}$/;
      if (phoneCheck.test(this.phone) == false) {
        this.phoneMsg = "请输入正确的手机号";
      } else {
        this.phoneMsg = "";
        this.isDis = true;
        this.timeOver = 60;
        let clock = window.setInterval(() => {
          this.timeOver--;
          if (this.timeOver < 1) {
            this.timeOver = "";
            this.isDis = false;
            window.clearInterval(clock); //关闭
          }
        }, 1000);
        getMassge({ phone: this.phone }).then(res => {
          if (res.data.code == "200") {
            Notify({ type: "success", message: "验证码已发送" });
          }
        });
      }
    },
    agreeBtn() {
      this.$router.push({ name: "login/userAgreement", query: { stust: 1 } });
    },
    agree1Btn() {
      this.$router.push({ name: "login/userAgreement", query: { stust: 2 } });
    },
    loginBtn() {
      if (this.radio == true) {
        const phoneCheck = /^1[3-9][0-9]{9}$/;
        const messageCheck = /^[0-9]\d{5}$/;
        if (phoneCheck.test(this.phone) == false) {
          this.phoneMsg = "请输入正确的手机号";
        } else if (messageCheck.test(this.message) == false) {
          this.messageMsg = "请输入六位验证码";
        } else {
          this.isTrue = true;
          login({ phone: this.phone, code: this.message }).then(res => {
            if (res.data.code == "00000") {
              Notify({ type: "success", message: "登录成功" });
              this.isTrue = false;
              localStorage.setItem("token", res.data.data.token);
              localStorage.setItem("phone", res.data.data.phone);
              setTimeout(() => {
                this.$router.push({ name: "mergePage" });
              }, 500);
            } else if (res.data.code == "403") {
              Notify({ type: "danger", message: res.data.msg });
              this.isTrue = false;
            } else {
              Notify({ type: "danger", message: res.data.msg });
              this.isTrue = false;
            }
          });
        }
      } else {
        Notify({ type: "warning", message: "请勾选服务" });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.login {
  width: 100%;
  background: #fff;
}
.form {
  padding: 2rem;
  box-sizing: border-box;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
.display {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.van-field__control {
  font-size: 16px;
}
</style>