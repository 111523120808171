<template>
  <div class="root">
    <div class="head">
      <p>{{ insData.Name }}</p>
    </div>
    <div class="line">
      <van-divider />
    </div>
    <div class="content">
      <!-- <div class="text">
        <div class="icon">
          <img :src="icon.">
        </div>
        <div class="title">所在省份:</div>
        <div class="data">{{ insData.Province }}</div>
      </div> -->
      <div class="text">
        <div class="icon">
          <img :src="icon.address">
        </div>
        <div class="title">机构地址：</div>
        <div class="data">{{ insData.Address }}</div>
      </div>
      <!-- <div class="text">
        <div class="icon">
          <img :src="icon.">
        </div>
        <div class="title">城市:</div>
        <div class="data">{{ insData.City }}</div>
      </div> -->
      <div class="text">
        <div class="icon">
          <img :src="icon.tel">
        </div>
        <div class="title">电话：</div>
        <div class="data">{{ insData.Tel }}</div>
      </div>
      <div class="text">
        <div class="icon">
          <img :src="icon.note">
        </div>
        <div class="title">注意事项：</div>
        <div class="data">详见预约短信</div>
      </div>
      <div class="map">
        <div id="app">
          <TianDiTu :position="position" v-if="this.position" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMenu } from "@/api/api";
import TianDiTu from '@/components/rich/TianDiTu.vue'
import { bd09towgs84 } from '@/utils/BD09ToWgs84.js';
export default {
  components: {
    TianDiTu
  },
  data() {
    return {
      insData: {},
      position: '',
      icon:{}
    };
  },
  mounted() {
    this.getIcon();
    this.insData = this.$route.query
    let arr = bd09towgs84(this.$route.query.PointX, this.$route.query.PointY)
    arr.push(this.$route.query.Name)
    this.position = arr.join()
  },
  methods: {
    async getIcon() {
      let res = await getMenu({ productCode: 'PC_CI52Y8XINYQM' })
      this.icon = res.data;
    },
  },
};
</script>
<style lang="less" scoped>
.root {
  background: #F1F4FB 100% 100%;

  .head {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .text {
      display: flex;
      width: 90%;
      margin: 0 auto;
      .icon{
        img{
          height: 1rem;
          margin-right: 1rem;
        }
      }
      // background-color: #fff;
      .title {
        width: 30%;
      }

      .data {
        width: 70%;
      }
    }
  }

  .map {
    padding-top: 2rem;
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>