<template>
  <div class="root" v-cloak>
    <div class="header" :style="{ backgroundImage: `url(${homeBack})` }">
      <div class="productType">
        <p>产品类型：{{ productType == '0' ? '保险服务' : (productType == '1' ? '体检' : '健康服务') }}</p>
      </div>
      <div class="orderNum">
        <p>订单数量：{{ orderNum }}</p>
        <p>合计：￥ {{ orderAmount }}</p>
      </div>
      <div class="dateSelect">
        <div class="date">
          <p>{{ nowDate }}</p>
        </div>
        <div class="icon" @click="selectDate">
          <van-icon name="arrow-down" />
        </div>
      </div>
    </div>
    <div class="content" v-show="!orderList.length == 0">
      <div class="policyCard" v-if="productType == '0'" v-for="item in orderList" :key="item.id">
        <div class="title">
          <p>保单号：{{ item.policyNo }}</p>
        </div>
        <van-divider :style="{ color: '#808080', borderColor: '#A9A9A9' }" />
        <p class="other">险种：{{ item.type }}</p>
        <p class="other">保费：{{ item.premium }}元</p>
        <p class="other">交易时间：{{ item.tradeTime }}</p>
        <p class="other">保险公司名称：{{ item.companyName }}</p>
        <p class="other">起止时间：{{ item.effectiveDate }} 至 {{ item.expireDate }}</p>
        <p class="other">投保人：{{ item.policyHolder }}</p>
        <p class="other">被保人：{{ item.policyInsured }}</p>
        <p class="other">备注：{{ item.remark }}</p>
        <p class="other">渠道来源：{{ item.salesName }}</p>
        <van-button v-show="item.policyUrl == null" disabled color="rgb(100,118,236)" plain
          size="small">暂无电子保单</van-button>
        <van-button v-show="item.policyUrl != null" color="rgb(100,118,236)" plain size="small"
          @click="viewEPolicy(item)">查看电子保单</van-button>
      </div>
      <div class="checkCard" v-if="productType == '1'" v-for="item in orderList" :key="item.id">
        <div class="title">
          <p class="orderId">订单号：{{ item.orderId }}</p>
        </div>
        <van-divider :style="{ color: '#808080', borderColor: '#A9A9A9' }" />
        <p class="other">体检总金额：{{ item.amount }}元</p>
        <p class="other">总人数：{{ item.headcount }}人</p>
        <p class="other">公司名称：{{ item.companyName }}</p>
        <p class="other">交易时间：{{ item.tradeTime }}</p>
      </div>
      <div class="serviceCard" v-if="productType == '2'" v-for="item in orderList" :key="item.id">
        <div class="title">
          <p class="orderId">订单号：{{ item.orderId }}</p>
        </div>
        <van-divider :style="{ color: '#808080', borderColor: '#A9A9A9' }" />
        <p class="other">健康服务名称：{{ item.serviceName }}</p>
        <p class="other">总金额：{{ item.amount }}元</p>
        <p class="other">公司名称：{{ item.companyName }}</p>
        <p class="other">交易时间：{{ item.tradeTime }}</p>
      </div>
    </div>
    <div class="empty" v-show="orderList.length == 0">
      <img :src="noOrderImg">
      <div class="text">
        <p>本月暂无订单</p>
      </div>
    </div>
    <van-popup v-model="popupShow" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate" :max-date="maxDate"
        :formatter="formatter" @cancel="pickCancel" @confirm="pickConfirm" />
    </van-popup>
  </div>
</template>
<script>
import { getMenu, queryOrderDetail } from '@/api/api'
export default {
  data() {
    return {
      salesCode: '',
      productType: '',
      orderList: ['demo'],
      orderNum: '0',
      //总金额
      orderAmount: '',
      //背景图片
      homeBack: '',
      //空状态图片
      noOrderImg: '',
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2099, 10, 1),
      currentDate: new Date(),
      //是否弹出年月选择窗
      popupShow: false,
      nowDate: '',
      fileType: ''
    }
  },
  methods: {
    viewEPolicy(item) {
      const type = item.policyUrl.slice(item.policyUrl.lastIndexOf('.') + 1).toLowerCase()
      if (type == 'pdf') {
        this.fileType = 'pdf'
      }
      if (type == 'jpg' || type == 'png' || type == 'jpeg') {
        this.fileType = 'img'
      }
      this.$router.push({
        name: 'policyFile',
        query: {
          fileType: this.fileType,
          policyUrl: item.policyUrl,
          nowDate: this.nowDate
        }
      })
    },
    //格式化日期
    formatDate(str) {
      let date = new Date(str),
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate() // 获取当前日(1-31)
      if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0
      return `${year}年${month}月`
    },
    getNowDate() {
      let date = new Date(),
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate() // 获取当前日(1-31)
      if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0
      this.nowDate = `${year}年${month}月`
    },
    //日期弹出窗确定事件
    pickConfirm(val) {
      this.nowDate = this.formatDate(val)
      let date = new Date(val)
      date = date.toLocaleDateString().replaceAll('/', '-')
      this.popupShow = false;
      this.orderDetail()
    },
    //日期弹出窗取消事件
    pickCancel() {
      this.popupShow = false;
    },
    //选择日期icon单击事件
    selectDate() {
      this.popupShow = true;
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    async orderDetail() {
      let res = await queryOrderDetail({ nowDate: this.nowDate, salesCode: this.salesCode, productType: this.productType })
      if (res.data.success) {
        this.orderList = res.data.data.orderList
        this.orderNum = res.data.data.orderNum
        this.orderAmount = res.data.data.amount
      }
    },
    //获取菜单信息
    async getMenuInfo() {
      let res = await getMenu({ productCode: 'PC_CDNMQNNIllDB' })
      this.homeBack = res.data.homeBack
      this.noOrderImg = res.data.noOrder
    },
  },
  async mounted() {
    this.salesCode = this.$route.query.salesCode
    this.productType = this.$route.query.productType
    this.nowDate = localStorage.getItem('nowDate')
    if(this.nowDate == undefined){
      this.getNowDate()
    }
    this.getMenuInfo()
    await this.orderDetail()
  },
  beforeDestroy() {
    localStorage.setItem('nowDate',this.nowDate)
  },
}
</script>
<style lang="less" scoped>
.root {
  // padding-top: 10px;
  width: 100%;
  background-color: rgb(241, 244, 251);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .header {
    position: fixed;
    height: 25vh;
    width: 100%;
    background-size: cover;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    color: #fff;
    z-index: 999;

    .productType {
      p {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .orderNum {
      display: flex;
      gap: 4rem;

      p {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .dateSelect {
      display: flex;
      align-items: baseline;
      font-size: 16px;
      gap: 5px;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-top: 30vh;
    padding-bottom: 20px;

    .policyCard,
    .checkCard,
    .serviceCard {
      display: flex;
      flex-direction: column;
      width: 95%;
      border-radius: 5px;
      background-color: #fff;

      p {
        padding-left: 1rem;
      }

      .title {
        height: 20px;
        padding-bottom: 15px;

        p {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

    .policyCard {
      .other {
        margin-top: -1px;
      }
    }
  }

  .empty {
    margin-top: 29vh;

    img {
      width: 100vw;
    }

    .text {
      p {
        font-size: 24px;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        text-align: center;
      }
    }
  }
}

[v-cloak] {
  display: none !important
}
</style>