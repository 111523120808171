<template>
  <div class="root">
    <div class="outer">
      <div class="head">
        <div class="title">
          <p>体检机构列表</p>
        </div>
      </div>
      <div class="filter">
        <van-dropdown-menu>
          <van-dropdown-item v-model="areaValue" :options="areaOptions" @change="onAreaChange" >
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
    </div>
    <div class="content">
      <div class="card" v-for="(item, index) in institutionList" :key="index" @click="viewInstitutionDetail(item)" v-if="institutionList.length > 0">
        <p class="title">{{ item.Name }}</p>
        <p class="other">城市：{{ item.Province }}</p>
        <p class="other">地址：{{ item.Address }}</p>
        <p class="other">电话：{{ item.Tel }}</p>
      </div>
      <div class="empty" v-if="institutionList.length == 0">
        <img :src="icon.empty">
      </div>
    </div>
    <div class="loading" v-if="loading">
      <van-loading size="34px">加载中...</van-loading>
    </div>
  </div>
</template>
<script>
import { getMenu } from "@/api/api";
import getProvinceData from '@/utils/provinceData'
import { Notify, DropdownMenu, DropdownItem } from "vant";
import { getCardInfoByToken, getMedicalInstitutionList } from "@/api/richApi";
export default {
  data() {
    return {
      loading:true,
      institutionList: [],
      areaValue: '全部省份',
      areaOptions: [
        { text: '全部商品', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 },
      ],
    };
  },
  async mounted() {
    await this.getIcon();
    await this.getInstitutionList();
    this.areaOptions = getProvinceData()
    setTimeout(()=>{
      this.loading = false
    },2000)
  },
  methods: {
    async getIcon() {
      let res = await getMenu({ productCode: 'PC_CI52Y8XINYQM' })
      this.icon = res.data;
    },
    async getInstitutionList() {
      let res = await getMedicalInstitutionList({
        PackageId: '',
        province: this.areaValue
      });
      if (res.data.code == '00000') {
        this.institutionList = res.data.data;
      } else {
        Notify({ type: "warning", message: res.data.msg });
      }
    },
    viewInstitutionDetail(item) {
      this.$router.push({
        name: 'institutionDetail',
        query: item
      })
    },
    onAreaChange(value){
      this.getInstitutionList();
    }
  },
};
</script>
<style lang="less" scoped>
.root {
  background: #F1F4FB 100% 100%;
  height: 100vh;
  position: relative;
  .head {
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      p {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }

  .content {
    margin-top: 9rem;
    .card {
      width: 90%;
      margin: 0 auto;
      border-radius: 10px;
      // height: 10rem;
      background-color: #fff;
      padding-left: 1rem;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 1rem;

      .title {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
  .empty{
    margin-top: -1rem;
    img{
      width: 100%;
    }
  }
}
.outer{
  position: fixed;
  background-color: #fff;
  width: 100%;
  top: 0;
}
</style>