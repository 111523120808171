<template>
  <!-- 健康档案 -->
  <div class="container">
    <div class="cardBox">
      <div
        class="display"
        style="border-bottom: 1px solid #f1f1f1; height: 60px"
      >
        <div style="font-weight: bold; font-size: 18px">基础健康信息</div>
        <div class="display">
          <van-button round size="small" type="default" @click="healthInfoBtn"
            >手动录入</van-button
          >
        </div>
      </div>
      <div style="height: 50px; line-height: 45px; color: #666">
        手动填写基础信息
      </div>
    </div>
    <div class="cardBox">
      <div
        class="display"
        style="border-bottom: 1px solid #f1f1f1; height: 60px"
      >
        <div style="font-weight: bold; font-size: 18px">门诊记录</div>
        <div class="display">
          <van-button
            round
            size="small"
            type="default"
            style="margin-right: 10px"
            @click="outpatientRecordBtn"
            >手动录入</van-button
          >
          <van-button
            round
            size="small"
            color="#3e77db"
            type="danger"
            @click="historyBtn((val = 1))"
            >历史记录</van-button
          >
        </div>
      </div>
      <div style="height: 50px; line-height: 45px; color: #666">
        可以上传您的门诊记录，也可以手动填写
      </div>
    </div>
    <div class="cardBox">
      <div
        class="display"
        style="border-bottom: 1px solid #f1f1f1; height: 60px"
      >
        <div style="font-weight: bold; font-size: 18px">购药记录</div>
        <div class="display">
          <van-button
            round
            size="small"
            type="default"
            style="margin-right: 10px"
            @click="buyMedicineHistoryBtn"
            >手动录入</van-button
          >
          <van-button
            round
            size="small"
            color="#3e77db"
            type="danger"
            @click="historyBtn((val = 2))"
            >历史记录</van-button
          >
        </div>
      </div>
      <div style="height: 50px; line-height: 45px; color: #666">
        可以上传您的购药记录，也可以手动填写
      </div>
    </div>
    <div class="cardBox">
      <div
        class="display"
        style="border-bottom: 1px solid #f1f1f1; height: 60px"
      >
        <div style="font-weight: bold; font-size: 18px">体检记录</div>
        <div class="display">
          <van-button
            round
            size="small"
            type="default"
            style="margin-right: 10px"
            @click="checkHistoryBtn"
            >手动录入</van-button
          >
          <van-button
            round
            size="small"
            color="#3e77db"
            type="danger"
            @click="historyBtn((val = 3))"
            >历史记录</van-button
          >
        </div>
      </div>
      <div style="height: 50px; line-height: 45px; color: #666">
        可以上传您的体检记录，也可以手动填写
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    //基础健康信息手动录入
    healthInfoBtn() {
      this.$router.push({ name: "mine/healthRecords/healthInfo" });
    },
    //门诊记录手动录入
    outpatientRecordBtn() {
      this.$router.push({ name: "mine/healthRecords/outpatientRecord" });
    },
    //购药记录手动录入
    buyMedicineHistoryBtn() {
      this.$router.push({ name: "mine/healthRecords/buyMedicineHistory" });
    },
    //体检记录手动录入
    checkHistoryBtn() {
      this.$router.push({ name: "mine/healthRecords/checkHistory" });
    },
    //历史记录
    historyBtn(val) {
      this.$router.push({
        name: "mine/healthRecords/historyInfo",
        query: {
          recordType: val,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  background: #f1f1f1;
  padding: 10px;
  box-sizing: border-box;
}

image {
  width: 100%;
  height: auto;
  display: block;
}

.bottom {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
}

.display {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardBox {
  width: 100%;
  height: 120px;
  background: #fff;
  border-radius: 10px;
  padding: 10px 15px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
</style>