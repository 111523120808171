<template>
  <div class="root">
    <div class="title">
      <p>检查报告</p>
    </div>
    <div class="content">
      <div class="card" v-for="(item, index) in reportList" :key="index" v-if="reportList.length != 0">
        <p>卡号：{{ item.cardNo }}</p>
        <p>检查号：{{ item.studyId }}</p>
        <div class="btn" @click="toCheckReport(item)">
          <p>查看报告</p>
        </div>
        <div class="empty" v-if="reportList.length == 0">
          <img src="icon.empty" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryReport } from "@/api/richApi";
import { getMenu } from "@/api/api";
export default {
  data() {
    return {
      icon: {},
      reportList: []
    };
  },
  async mounted() {
    await this.getReport();
    await this.getIcon();
  },
  methods: {
    async getIcon() {
      let res = await getMenu({ productCode: 'PC_CI52Y8XINYQM' })
      this.icon = res.data;
    },
    async getReport() {
      let res = await queryReport({})
      this.reportList = res.data.data;
    },
    toCheckReport(item){
      this.$router.push({
        name:'checkReport',
        query:{
          imgUrl:item.checkRecordImgUrl,
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
*{
  margin: 0;
}
.root {
  background-color: #F1F4FB;
  height: 100vh;

  .title {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
    }
  }

  .content {
    width: 95%;
    margin: 0 auto;

    .card {
      margin-bottom: 1rem;
      border-radius: 10px;
      height: 6rem;
      background-color: #fff;
      padding: 1rem;
      position: relative;

      p {
        font-size: 18px;
      }

      .btn {
        position: absolute;
        bottom: 50%;
        top: 50%;
        /* 使用transform属性进行垂直居中微调，负的50%的高度是为了向上偏移自身高度的一半 */
        transform: translateY(-50%);
        right: 2rem;
        background: linear-gradient(to bottom, rgb(124, 120, 241), rgb(71, 116, 227));
        height: 2rem;
        width: 6rem;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }

  .empty {
    img {
      margin-top: 4rem;
      width: 100%;
    }
  }
}
</style>