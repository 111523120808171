var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"root"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"avatar"},[_c('img',{attrs:{"src":_vm.icon.companyLogo}})]),_c('div',{staticClass:"mobile"},[_c('p',[_vm._v(_vm._s(_vm.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')))])])]),_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"line"},[_c('van-divider')],1),_c('div',{staticClass:"main"},[_c('div',{staticClass:"item",on:{"click":_vm.myCheck}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":_vm.icon.myCheck}})]),_vm._m(1)]),_c('div',{staticClass:"item",on:{"click":_vm.viewInstitutions}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":_vm.icon.checkIns}})]),_vm._m(2)]),_c('div',{staticClass:"item",on:{"click":_vm.viewMyReport}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":_vm.icon.checkRecord}})]),_vm._m(3)]),_c('div',{staticClass:"item",on:{"click":_vm.goUserInfo}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":_vm.icon.bookInfo}})]),_vm._m(4)])])]),_c('div',{staticClass:"footer"},[_vm._m(5),_c('div',{staticClass:"line"},[_c('van-divider')],1),_c('div',{staticClass:"main"},[_c('div',{staticClass:"item",on:{"click":_vm.goQuestion}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":_vm.icon.question}})]),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('p',[_vm._v("体检服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("我的体检")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("体检机构信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("我的报告")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("预约信息管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('p',[_vm._v("其他")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("常见问题")])])
}]

export { render, staticRenderFns }