<template>
  <div class="container">
    <div
      style="
        background: #fff;
        width: 100%;
        height: 170px;
        border-radius: 10px 10px 0 0;
      "
    >
      <div class="cardFirst">
        <div style="width: 20%">
          <img
            style="width: 55px; height: auto"
            src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AFlogo1.png"
          />
        </div>
        <div style="flex: 1; text-align: left; margin-left: 3%">
          <div style="font-weight: bold; font-size: 19px; height: 30px">
            {{ this.phone }}
            <img
              v-if="info.vip == 0"
              src="@/assets/会员.png"
              style="width: 19px; height: auto; margin-left: 3px"
              alt
            />
          </div>
          <div style="display: flex; align-items: center">
            <van-button round plain type="info" size="mini" @click="familyBtn">健康档案成员</van-button>
          </div>
        </div>
        <div>
          <van-button
            round
            plain
            type="info"
            size="small"
            style="padding: 10px 20px; font-weight: bold; font-size: 13px"
            v-if="this.phone == '请登录'"
            @click="goLogin"
          >登录</van-button>
        </div>
      </div>
      <div class="cardLast">
        <div>
          <div style="height: 25px">积分</div>
          <div style="font-weight: bold; font-size: 22px">0</div>
        </div>
        <div style="border: 1px solid #666; height: 30px"></div>
        <div @click="goMySaleCard">
          <div style="height: 25px">卡劵</div>
          <div style="font-weight: bold; font-size: 22px">{{ cardNum }}</div>
        </div>
        <div style="border: 1px solid #666; height: 30px"></div>
        <div>
          <div style="height: 25px">津贴(元)</div>
          <div style="font-weight: bold; font-size: 22px">0</div>
        </div>
      </div>
    </div>
    <div class="vipBox">
      <p
        style="
          color: #3e77db;
          font-size: 17px;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 14px;
        "
      >购买vip权益立享服务</p>
      <p>
        <van-button round type="info" color="#3e77db" size="small" @click="vipClick">
          <p
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 13px;
              padding: 4px 20px;
              box-sizing: border-box;
            "
          >会员权益</p>
        </van-button>
      </p>
    </div>
    <!-- 第一个框 -->
    <div class="memberBox" v-if="infos.flag == true">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          height: 85%;
        "
      >
        <p
          style="
            color: #3e77db;
            font-size: 18px;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 14px;
          "
        >
          {{ infos.name }}
          <img
            src="@/assets/会员.png"
            style="width: 19px; height: auto; margin: 5px"
            alt
          />
        </p>
        <p>
          <van-button round type="info" color="#3e77db" size="small" @click="equityPersonnel">
            <p
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 13px;
                padding: 4px;
              "
            >
              <span v-if="infos.name != '健康权益' && infos.num == 0">点击使用({{ infos.num }})</span>
              <span v-else>查看全部({{ infos.num }})</span>
              <img src="@/assets/更多.png" style="width: 8px; height: auto; margin-left: 5px" />
            </p>
          </van-button>
        </p>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 20px;
          font-size: 13px;
          color: #1658c9;
        "
      >
        <p @click="shows = true">
          权益详情
          <van-icon name="question-o" style="margin-left: 3px" />
        </p>
        <p v-if="infos.expireDate != ''">有效期至{{ infos.expireDate }}</p>
      </div>
    </div>
    <van-popup
      v-model:show="shows"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div style="padding: 10px; box-sizing: border-box; font-size: 14px">
        <h3 style="text-align: center; margin: 5px 0">权益详情</h3>
        <van-divider :hairline="false" />
        <!-- <ul
          v-for="(item, index) in infos.equityInfo"
          :key="index"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 55px;
            margin-bottom:10px;
          "
        >
          <li style="width: 35%; font-weight: bold">{{ item.productName }}:</li>
          <li style="flex: 1; display: flex; justify-content: end">{{ item.equityInfo }}</li>
        </ul>-->
        <div class="title">会员专属六大权益</div>
        <van-grid :gutter="10" :column-num="3" icon-size="40" :border="false">
          <van-grid-item
            v-for="(item, index) in imgData"
            :key="index"
            :icon="item.img"
            :text="item.text"
          />
        </van-grid>
      </div>
    </van-popup>
    <!-- 第二个框 -->
    <div class="memberBox" v-if="info.flag == true">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          height: 85%;
        "
      >
        <p
          style="
            color: #3e77db;
            font-size: 18px;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 14px;
          "
        >
          {{ info.name }}
          <img
            src="@/assets/会员.png"
            style="width: 19px; height: auto; margin: 5px"
            alt
          />
        </p>
        <p>
          <van-button round type="info" color="#3e77db" size="small" @click="equityPersonnel">
            <p
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 13px;
                padding: 4px;
              "
            >
              <span v-if="info.name != '健康权益' && info.num == 0">点击使用({{ info.num }})</span>
              <span v-else>查看全部({{ info.num }})</span>
              <img src="@/assets/更多.png" style="width: 8px; height: auto; margin-left: 5px" />
            </p>
          </van-button>
        </p>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 20px;
          font-size: 13px;
          color: #1658c9;
        "
      >
        <p @click="show = true">
          权益详情
          <van-icon name="question-o" style="margin-left: 3px" />
        </p>
        <p v-if="info.expireDate != ''">有效期至{{ info.expireDate }}</p>
      </div>
    </div>
    <van-popup
      v-model:show="show"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div style="padding: 10px; box-sizing: border-box; font-size: 14px">
        <h3 style="text-align: center; margin: 5px 0">权益详情</h3>
        <van-divider :hairline="false" />
        <ul
          v-for="(item, index) in info.equityInfo"
          :key="index"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 55px;
          "
        >
          <li style="width: 35%; font-weight: bold">{{ item.productName }}:</li>
          <li style="flex: 1; display: flex; justify-content: end">{{ item.equityInfo }}</li>
        </ul>
      </div>
    </van-popup>
    <!-- <van-dialog v-model:show="show"> </van-dialog> -->
    <div
      style="
        width: 100%;
        background: #fff;
        border-radius: 10px;
        padding: 10px;
        box-sizing: border-box;
      "
    >
      <div
        style="
          width: 100%;
          padding: 13px 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #ccc;
        "
        @click="myOrderBtn"
      >
        <van-row style="text-align: center">
          <van-col span="2">
            <img
              style="width: 17px; height: auto"
              src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%84%E8%AE%A2%E5%8D%95%403x.png"
            />
          </van-col>
          <van-col
            span="19"
            style="
              text-align: left;
              font-weight: bold;
              font-size: 14px;
              padding-left: 10px;
            "
          >我的订单</van-col>
          <van-col span="3" style="text-align: right">
            <van-icon name="arrow" />
          </van-col>
        </van-row>
      </div>
      <div
        v-if="false"
        style="
          width: 100%;
          padding: 13px 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #ccc;
        "
        @click="emptyBtn"
      >
        <van-row style="text-align: center">
          <van-col span="2">
            <img
              style="width: 18px; height: auto"
              src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%97%B9%E9%92%9F%20(1)%403x.png"
            />
          </van-col>
          <van-col
            span="19"
            style="
              text-align: left;
              font-weight: bold;
              font-size: 14px;
              padding-left: 10px;
            "
          >服务预约</van-col>
          <van-col span="3" style="text-align: right">
            <van-icon name="arrow" />
          </van-col>
        </van-row>
      </div>
      <div
        style="
          width: 100%;
          padding: 13px 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #ccc;
        "
        @click="reportBtn"
      >
        <van-row style="text-align: center">
          <van-col span="2">
            <img
              style="width: 18px; height: auto"
              src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%8A%A5%E5%91%8A%E8%A7%A3%E8%AF%BB.png"
            />
          </van-col>
          <van-col
            span="19"
            style="
              text-align: left;
              font-weight: bold;
              font-size: 14px;
              padding-left: 10px;
            "
          >报告解读</van-col>
          <van-col span="3" style="text-align: right">
            <van-icon name="arrow" />
          </van-col>
        </van-row>
      </div>
      <div
        style="
          width: 100%;
          padding: 13px 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #ccc;
        "
        @click="healthRecordsBtn"
      >
        <van-row style="text-align: center">
          <van-col span="2">
            <img
              style="width: 19px; height: auto"
              src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E5%81%A5%E5%BA%B7%E6%A1%A3%E6%A1%88%20(1)%403x.png"
            />
          </van-col>
          <van-col
            span="19"
            style="
              text-align: left;
              font-weight: bold;
              font-size: 14px;
              padding-left: 10px;
            "
          >健康档案上传</van-col>
          <van-col span="3" style="text-align: right">
            <van-icon name="arrow" />
          </van-col>
        </van-row>
      </div>
      <van-collapse
        v-if="false"
        v-model="activeName"
        boolean
        style="border-bottom: 1px solid #ccc"
        right-icon="none"
      >
        <van-collapse-item>
          <template #title>
            <van-row style="text-align: center">
              <van-col span="2" style="position: relative">
                <img
                  style="
                    width: 19px;
                    height: auto;
                    position: absolute;
                    margin-left: -13px;
                    margin-top: 3px;
                  "
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E5%81%A5%E7%AE%A1.png"
                />
              </van-col>
              <van-col
                span="19"
                style="
                  text-align: left;
                  font-weight: bold;
                  font-size: 14px;
                  padding-left: 8px;
                  color: #000;
                "
              >健管服务</van-col>
            </van-row>
          </template>
          <template #right-icon style="position: relative">
            <div style="text-align: right; position: relative; right: -5px">
              <van-icon name="arrow" style="margin-left: 20px" />
            </div>
          </template>
          <div
            style="
              width: 100%;
              padding: 13px 10px;
              box-sizing: border-box;
              border-bottom: 1px solid #ccc;
            "
            @click="emptyBtn"
          >
            <van-row style="text-align: center">
              <van-col span="2">
                <img
                  style="width: 18px; height: auto"
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E7%97%85%E5%8E%86.png"
                />
              </van-col>
              <van-col
                span="19"
                style="
                  text-align: left;
                  font-weight: bold;
                  font-size: 14px;
                  padding-left: 10px;
                  color: #000;
                "
              >病例哨兵</van-col>
              <van-col span="3" style="text-align: right">
                <van-icon name="arrow" />
              </van-col>
            </van-row>
          </div>
          <div
            style="
              width: 100%;
              padding: 13px 10px;
              box-sizing: border-box;
              border-bottom: 1px solid #ccc;
            "
            @click="emptyBtn"
          >
            <van-row style="text-align: center">
              <van-col span="2">
                <img
                  style="width: 18px; height: auto"
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E7%96%BE%E7%97%85%E9%A2%84%E9%98%B2.png"
                />
              </van-col>
              <van-col
                span="19"
                style="
                  text-align: left;
                  font-weight: bold;
                  font-size: 14px;
                  padding-left: 10px;
                  color: #000;
                "
              >疾病预防</van-col>
              <van-col span="3" style="text-align: right">
                <van-icon name="arrow" />
              </van-col>
            </van-row>
          </div>
          <div
            style="
              width: 100%;
              padding: 13px 10px;
              box-sizing: border-box;
              border-bottom: 1px solid #ccc;
            "
            @click="emptyBtn"
          >
            <van-row style="text-align: center">
              <van-col span="2">
                <img
                  style="width: 18px; height: auto"
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%8B%A9%E5%8C%BB.png"
                />
              </van-col>
              <van-col
                span="19"
                style="
                  text-align: left;
                  font-weight: bold;
                  font-size: 14px;
                  padding-left: 10px;
                  color: #000;
                "
              >择医推荐</van-col>
              <van-col span="3" style="text-align: right">
                <van-icon name="arrow" />
              </van-col>
            </van-row>
          </div>
          <div
            style="
              width: 100%;
              padding: 13px 10px;
              box-sizing: border-box;
              border-bottom: 1px solid #ccc;
            "
            @click="emptyBtn"
          >
            <van-row style="text-align: center">
              <van-col span="2">
                <img
                  style="width: 18px; height: auto"
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E5%A4%8D%E8%AF%8A.png"
                />
              </van-col>
              <van-col
                span="19"
                style="
                  text-align: left;
                  font-weight: bold;
                  font-size: 14px;
                  padding-left: 10px;
                  color: #000;
                "
              >复诊管理</van-col>
              <van-col span="3" style="text-align: right">
                <van-icon name="arrow" />
              </van-col>
            </van-row>
          </div>
          <div
            style="
              width: 100%;
              padding: 13px 10px;
              box-sizing: border-box;
              border-bottom: 1px solid #ccc;
            "
            @click="emptyBtn"
          >
            <van-row style="text-align: center">
              <van-col span="2">
                <img
                  style="width: 18px; height: auto"
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E5%89%AF%E4%BD%9C%E7%94%A8.png"
                />
              </van-col>
              <van-col
                span="19"
                style="
                  text-align: left;
                  font-weight: bold;
                  font-size: 14px;
                  padding-left: 10px;
                  color: #000;
                "
              >副作用管理</van-col>
              <van-col span="3" style="text-align: right">
                <van-icon name="arrow" />
              </van-col>
            </van-row>
          </div>
          <div
            style="
              width: 100%;
              padding: 13px 10px;
              box-sizing: border-box;
              border-bottom: 1px solid #ccc;
            "
            @click="emptyBtn"
          >
            <van-row style="text-align: center">
              <van-col span="2">
                <img
                  style="width: 18px; height: auto"
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%9D%B6%E7%82%B9%E6%9F%A5%E8%8D%AF.png"
                />
              </van-col>
              <van-col
                span="19"
                style="
                  text-align: left;
                  font-weight: bold;
                  font-size: 14px;
                  padding-left: 10px;
                  color: #000;
                "
              >靶点查药</van-col>
              <van-col span="3" style="text-align: right">
                <van-icon name="arrow" />
              </van-col>
            </van-row>
          </div>
          <div style="width: 100%; padding: 13px 10px; box-sizing: border-box" @click="serviceBtn">
            <van-row style="text-align: center">
              <van-col span="2">
                <img
                  style="width: 19px; height: auto"
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E5%92%A8%E8%AF%A2.png"
                />
              </van-col>
              <van-col
                span="19"
                style="
                  text-align: left;
                  font-weight: bold;
                  font-size: 14px;
                  padding-left: 10px;
                  color: #000;
                "
              >线上咨询</van-col>
              <van-col span="3" style="text-align: right">
                <van-icon name="arrow" />
              </van-col>
            </van-row>
          </div>
        </van-collapse-item>
      </van-collapse>
      <div
        style="
          width: 100%;
          padding: 13px 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #ccc;
        "
        @click="serviceBtn"
      >
        <van-row style="text-align: center">
          <van-col span="2">
            <img
              style="width: 19px; height: auto"
              src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E5%AE%A2%E6%9C%8D%403x.png"
            />
          </van-col>
          <van-col
            span="19"
            style="
              text-align: left;
              font-weight: bold;
              font-size: 14px;
              padding-left: 10px;
            "
          >我的客服</van-col>
          <van-col span="3" style="text-align: right">
            <van-icon name="arrow" />
          </van-col>
        </van-row>
      </div>
      <div
        style="
          width: 100%;
          padding: 13px 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #ccc;
        "
        @click="backUserBtn"
      >
        <van-row style="text-align: center">
          <van-col span="2">
            <img
              style="width: 18px; height: auto"
              src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%B3%A8%E9%94%80%E8%AF%81%E4%BB%B6.png"
            />
          </van-col>
          <van-col
            span="19"
            style="
              text-align: left;
              font-weight: bold;
              font-size: 14px;
              padding-left: 10px;
            "
          >注销账号</van-col>
          <van-col span="3" style="text-align: right">
            <van-icon name="arrow" />
          </van-col>
        </van-row>
      </div>
      <div style="width: 100%; padding: 13px 0px; box-sizing: border-box">
        <van-button color="#3e77db" block @click="backUser">退出登录</van-button>
      </div>
    </div>
    <div style="height: 4rem"></div>
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Notify } from "vant";
import { queryEquityByCustomerCode, checkVIP } from "@/api/api";
import { queryCardCount } from "@/api/cardApi";
export default {
  data() {
    return {
      phone: "请登录",
      activeName: ["1"],
      info: {},
      infos: {},
      show: false,
      shows: false,
      imgData: [
        {
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E5%81%A5%E5%BA%B7%E5%92%A8%E8%AF%A2%403x.png",
          text: "健康咨询"
        },
        {
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E6%85%A2%E7%97%85%E6%8C%87%E5%AF%BC%403x.png",
          text: "慢病指导"
        },
        {
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E7%94%A8%E8%8D%AF%E6%8C%87%E5%AF%BC%403x.png",
          text: "用药指引"
        },
        {
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E7%94%9F%E6%B4%BB%E6%8C%87%E5%AF%BC%403x.png",
          text: "饮食运动生活方式指导"
        },
        {
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E4%BD%93%E6%A3%80%E6%8C%87%E5%AF%BC%403x.png",
          text: "体检指导"
        },
        {
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E6%8A%A5%E5%91%8A%E8%A7%A3%E8%AF%BB%403x.png",
          text: "报告解读"
        },
        {
          img: "https://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E8%B4%AD%E8%8D%AF5%E6%8A%98.png",
          text: "购药五折优惠"
        }
      ],
      cardNum:'0'
    };
  },
  mounted() {
    let equityCode = sessionStorage.getItem("equityCode");
    let channelCode = sessionStorage.getItem("channelCode");
    queryEquityByCustomerCode({
      flag: true,
      equityCode: equityCode,
      channelCode: channelCode,
      token: localStorage.getItem("token")
    }).then(res => {
      this.info = res.data;
    });
    checkVIP({
      flag: true,
      token: localStorage.getItem("token")
    }).then(res => {
      this.infos = res.data;
    });
    const phone = localStorage.getItem("phone");
    if (phone) {
      this.phone = phone;
    } else {
      this.phone = "请登录";
    }
    queryCardCount().then(res => {
      this.cardNum = res.data.data.num
    });
  },
  methods: {
    //去登陆
    goLogin() {
      this.$router.push({ name: "login" });
    },
    //健康权益成员
    equityPersonnel() {
      if (localStorage.getItem("token")) {
        this.$router.push({ name: "mine/equityPersonnel" });
      } else {
        Notify({ type: "warning", message: "请先登录" });
        setTimeout(() => {
          this.$router.push({ name: "login" });
        }, 800);
      }
    },
    //健康档案成员
    familyBtn() {
      if (localStorage.getItem("token")) {
        this.$router.push({ name: "mine/familyInfo" });
      } else {
        Notify({ type: "warning", message: "请先登录" });
        setTimeout(() => {
          this.$router.push({ name: "login" });
        }, 800);
      }
    },
    // 报告解读
    reportBtn() {
      if (localStorage.getItem("token")) {
        this.$router.push({ name: "mine/reportHistory" });
      } else {
        Notify({ type: "warning", message: "请先登录" });
        setTimeout(() => {
          this.$router.push({ name: "login" });
        }, 800);
      }
    },
    //健康档案
    healthRecordsBtn() {
      if (localStorage.getItem("token")) {
        this.$router.push({ name: "mine/healthRecords" });
      } else {
        Notify({ type: "warning", message: "请先登录" });
        setTimeout(() => {
          this.$router.push({ name: "login" });
        }, 800);
      }
    },
    //我的订单
    myOrderBtn() {
      if (localStorage.getItem("token")) {
        this.$router.push({ name: "mine/myOrder" });
      } else {
        Notify({ type: "warning", message: "请先登录" });
        setTimeout(() => {
          this.$router.push({ name: "login" });
        }, 800);
      }
    },
    //服务预约
    emptyBtn() {
      if (localStorage.getItem("token")) {
        this.$router.push({ name: "blankSpace" });
      } else {
        Notify({ type: "warning", message: "请先登录" });
        setTimeout(() => {
          this.$router.push({ name: "login" });
        }, 800);
      }
    },
    //我的客服
    serviceBtn() {
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfc964776c9b0909cb6";
    },
    //注销账号
    backUserBtn() {
      Dialog.confirm({
        title: "确定要注销账号吗？"
      })
        .then(() => {
          // on confirm
          this.phone = "请登录";
          localStorage.clear();
          Notify({ type: "danger", message: "退出成功" });
          queryEquityByCustomerCode({ flag: false }).then(res => {
            this.info = res.data;
          });
          this.cardNum = '0'
        })
        .catch(() => {
          // on cancel
        });
    },
    // 会员权益按钮
    vipClick() {
      if (localStorage.getItem("token")) {
        this.$router.push({ name: "mine/memberPurchase" });
      } else {
        Notify({ type: "warning", message: "请先登录" });
        setTimeout(() => {
          this.$router.push({ name: "login" });
        }, 800);
      }
    },
    //退出登录
    backUser() {
      Dialog.confirm({
        title: "确定要退出登录吗？"
      })
        .then(() => {
          // on confirm
          this.phone = "请登录";
          localStorage.clear();
          Notify({ type: "danger", message: "退出成功" });
          queryEquityByCustomerCode({ flag: false }).then(res => {
            this.info = res.data;
          });
          this.cardNum = '0'
        })
        .catch(() => {
          // on cancel
        });
    },
    //卡券查看
    goMySaleCard(){
      this.$router.push({ name:'myCard' });
    }
  }
};
</script>
<style lang="less" scoped>
.displayBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: bold;
  margin-bottom: 15px;
}
.container {
  width: 100%;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
}

image {
  width: 100%;
  height: auto;
  display: block;
}

.cardFirst {
  width: 100%;
  height: 90px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.memberBox {
  width: 100%;
  height: 150px;
  position: relative;
  margin: 10px 0;
  border-radius: 5px;
  background: url("@/assets/权益背景.png");
  background-size: 100% 100%;
  padding: 12px;
  box-sizing: border-box;
}

.cardLast {
  text-align: center;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  box-sizing: border-box;
}
.vipBox {
  width: 100%;
  height: 3rem;
  background: url("@/assets/会员背景图.png");
  background-size: 100% 100%;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
}
</style>