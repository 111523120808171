<template>
  <div class="memberPurchase">
    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <van-loading vertical size="33px">加载中...</van-loading>
      </div>
    </van-overlay>
    <div class="topBox">
      <div style="display: flex; justify-content: flex-start; align-items: center">
        <img
          style="width: 55px; height: auto"
          src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AFlogo1.png"
        />
        <ul style="margin-left: 10px">
          <li style="font-weight: bold; font-size: 19px; margin: 5px 0">
            {{ phone }}
            <img
              v-if="this.vipEffectiveDate != null || this.vipExpireDate != null"
              src="@/assets/会员.png"
              style="width: 18px; height: auto"
            />
          </li>
          <li
            style="
              font-size: 12px;
              color: #3f32ff;
              transform: scale(0.9);
              transform-origin: 0;
              white-space: nowrap;
            "
          >购买健康服务卡成为会员,享受不限次图文咨询服务</li>
        </ul>
      </div>
      <div
        v-if="this.vipEffectiveDate != null || this.vipExpireDate != null"
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <img style="width: 55px; height: auto" />
        <ul style="margin-left: 10px">
          <li style="font-size: 13px; margin: 5px 0">有效期至:{{ vipExpireDate }}</li>
        </ul>
      </div>
      <div v-else style="height: 1.7rem"></div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1.2rem;
          font-weight: bold;
          color: #3f32ff;
        "
      >
        <img
          src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/VIP%403x.png"
          style="width: 40px; height: auto; margin-right: 5px"
        />
        会员专属的升级健康服务
      </div>
    </div>
    <div class="box">
      <div class="title">会员健康服务卡</div>
      <div
        v-for="(item, index) in thProductList"
        :key="index"
        :class="selectedIndex !== index ? 'cardBox' : 'cardBoxAction'"
        @click="cardClick(index)"
      >
        <ul>
          <li style="font-weight: bold; margin-bottom: 8px">{{ item.productName }}</li>
          <li style="font-size: 12px; color: #666">{{ item.productDesc }}</li>
        </ul>
        <div>
          <van-button
            round
            size="small"
            color="#5E52F8"
            type="info"
            @click="vipBuy(item, index)"
            style="
              font-size: 12px;
              transform: scale(0.8);
              transform-origin: 0;
              padding: 10px 15px;
            "
          >
            ￥
            <span style="font-size: 17px; margin: 0 5px 0 2px">
              {{
              item.premium
              }}
            </span>购买
          </van-button>
        </div>
      </div>
      <van-checkbox v-model="radio" icon-size="16px">
        我已阅读并同意
        <a style="color: #3e77db" @click="agreeBtn">《用户服务协议》</a>
        <a style="color: #3e77db" @click="agree1Btn">《用户隐私协议》</a>
      </van-checkbox>
    </div>
    <div class="box">
      <div class="title">会员专属六大权益</div>
      <van-grid :gutter="10" :column-num="3" icon-size="40" :border="false">
        <van-grid-item
          v-for="(item, index) in imgData"
          :key="index"
          :icon="item.img"
          :text="item.text"
        />
      </van-grid>
    </div>
    <div style="height: 0.5rem"></div>
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Notify } from "vant";
import { getVipInfo, buyVIP } from "@/api/api";
export default {
  data() {
    return {
      phone: "",
      vipEffectiveDate: null,
      vipExpireDate: null,
      thProductList: [],
      selectedIndex: 0,
      show: false,
      radio: false,
      imgData: [
        {
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E5%81%A5%E5%BA%B7%E5%92%A8%E8%AF%A2%403x.png",
          text: "健康咨询"
        },
        {
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E6%85%A2%E7%97%85%E6%8C%87%E5%AF%BC%403x.png",
          text: "慢病指导"
        },
        {
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E7%94%A8%E8%8D%AF%E6%8C%87%E5%AF%BC%403x.png",
          text: "用药指引"
        },
        {
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E7%94%9F%E6%B4%BB%E6%8C%87%E5%AF%BC%403x.png",
          text: "饮食运动生活方式指导"
        },
        {
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E4%BD%93%E6%A3%80%E6%8C%87%E5%AF%BC%403x.png",
          text: "体检指导"
        },
        {
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E6%8A%A5%E5%91%8A%E8%A7%A3%E8%AF%BB%403x.png",
          text: "报告解读"
        },
        {
          img: "https://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E8%B4%AD%E8%8D%AF5%E6%8A%98.png",
          text: "购药五折优惠"
        }
      ]
    };
  },
  mounted() {
    getVipInfo().then(res => {
      this.phone = res.data.thCustomer.phone;
      this.vipEffectiveDate = res.data.thCustomer.vipEffectiveDate;
      this.vipExpireDate = res.data.thCustomer.vipExpireDate;
      this.thProductList = res.data.thProductList;
    });
  },
  methods: {
    cardClick(index) {
      this.selectedIndex = index;
    },
    agreeBtn() {
      this.$router.push({ name: "login/userAgreement", query: { stust: 3 } });
    },
    agree1Btn() {
      this.$router.push({ name: "login/userAgreement", query: { stust: 2 } });
    },
    // 点击金额按钮
    vipBuy(item, index) {
      if (this.radio == true) {
        this.selectedIndex = index;
        this.show = true;
        if (localStorage.getItem("token")) {
          buyVIP({
            productCode: item.productCode,
            totalAmount: item.premium * 1
          }).then(res => {
            this.show = false;
            window.location.href = res.data.qrCode;
          });
        } else {
          Notify({ type: "warning", message: "请先登录" });
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 800);
        }
      } else {
        Notify({ type: "warning", message: "请勾选服务" });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.memberPurchase {
  width: 100%;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
}
.topBox {
  width: 100%;
  height: 160px;
  position: relative;
  border-radius: 5px;
  background: url("@/assets/会员购买页背景.png");
  background-size: 100% 100%;
  padding: 12px;
  box-sizing: border-box;
}
.box {
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin: 10px 0;
  border-radius: 5px;
}
.title {
  font-weight: bold;
  margin-bottom: 15px;
}
.cardBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 4.5rem;
  margin-bottom: 12px;
  background: url("http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E7%BC%96%E7%BB%84%2010%403x.png");
  background-size: 100% 100%;
}
.cardBoxAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 4.5rem;
  margin-bottom: 12px;
  background: url("http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BC%9A%E5%91%98%E9%A1%B5%E9%9D%A2/%E7%BC%96%E7%BB%84%208%403x.png");
  background-size: 100% 100%;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
/deep/.van-grid-item {
  text-align: center;
}
/deep/.van-grid-item__icon + .van-grid-item__text {
  margin-top: 10px;
}
/deep/.van-grid-item__content {
  height: 6rem;
  background: #f5f6f7;
}
</style>