import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import { Notify } from 'vant';
import * as directive from "@/directive";

Object.keys(directive).forEach(key => {
  Vue.directive(key, directive[key])
})
Vue.use(Vant);
Vue.use(Notify);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
