<template>
  <div class="root">
    <img :src="imgUrl" style="width: 100vw;">
  </div>
</template>
<script>
import { getMenu } from "@/api/api";
import { DownloadReport } from "@/api/richApi";
export default {
  data() {
    return {
      imgUrl: '',
      StudyId: '',
      CheckCardNo: '',
      OriPwd: ''
    };
  },
  async mounted() {
    await this.getRouteParams();
    await this.getImgUrl();
  },
  methods: {
    getRouteParams() {
      this.StudyId = this.$route.query.StudyId
      this.CheckCardNo = this.$route.query.CheckCardNo
      this.OriPwd = this.$route.query.OriPwd
    },
    async getImgUrl() {
      let imgUrl = this.$route.query.imgUrl
      if (imgUrl) {
        this.imgUrl = imgUrl
      } else {
        let res = await DownloadReport({
          StudyId: this.StudyId,
          CheckCardNo: this.CheckCardNo,
          OriPwd: this.OriPwd
        })
        this.imgUrl = res.data.data.url
      }
    }
  },
};
</script>
<style lang="less" scoped>
.root {
  background-color: #F1F4FB;

}
</style>