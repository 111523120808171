<template>
  <!-- 普惠好药两个按钮 -->
  <div class="container">
    <br />
    <br />
    <br />
    <br />
    <br />
    <van-button class="btn" block @click="buyVip">购买vip</van-button>
    <p style="margin: 90px 0;font-size: 16px;font-weight: bold;">或</p>
    <van-button class="btn" block @click="getCard">激活权益卡</van-button>
  </div>
</template>
<script>
import { Notify } from "vant";
import { Dialog } from "vant";
import { reportQuery } from "@/api/api";
export default {
  data() {
    return {};
  },
  methods: {
    buyVip() {
      this.$router.push({ name: "mine/memberPurchase" });
    },
    getCard() {
      this.$router.push({ name: "home/claimAnInterest" });
    }
  }
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
  margin: 0px;
  display: inline-block;
  text-align: center;
}
.btn {
  background-image: linear-gradient(to right, #8a79f3, #3830fc);
  color: white;
  font-size: 16px;
  width: 85%;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
}
</style>