<template>
  <!-- 我的页面健康权益成员查看详情 -->
  <div class="container">
    <div style="flex: 1; overflow: auto">
      <div class="box">
        <h3 style="margin: 0px">{{ form.equityName }}</h3>
        <p>权益卡号：{{ form.cardCode }}</p>
        <p>有效期至：{{ form.expireDate }}</p>
      </div>
      <div class="box">
        <h3 style="margin: 0px">权益人信息</h3>
        <van-divider :hairline="false" />
        <van-row>
          <van-col span="12">权益人</van-col>
          <van-col span="12" style="text-align: right">{{ form.name }}</van-col>
        </van-row>
        <van-divider :hairline="false" />
        <van-row>
          <van-col span="12">手机号</van-col>
          <van-col span="12" style="text-align: right">{{
            form.phone
          }}</van-col>
        </van-row>
      </div>
      <div class="box">
        <h3 style="margin: 0px">权益详情</h3>
        <van-divider :hairline="false" />
        <van-row v-for="(item, index) in this.form.productInfo" :key="index">
          <van-col span="20">
            <van-col span="24" style="margin-bottom: 10px; font-weight: bold">{{
              item.productName
            }}</van-col>
            <van-col span="24">有效期至：{{ item.expireDate }}</van-col>
          </van-col>
          <van-col span="4" style="margin: 15px 0; font-size: 12px">
            <span v-if="item.num != '不限次'">次数:</span
            >{{ item.num }}</van-col
          >
          <van-col span="24"> <van-divider :hairline="false" /> </van-col>
        </van-row>
      </div>
    </div>
    <div style="height: 55px; padding: 10px; box-sizing: border-box">
      <van-button type="info" round block color="#3e77db" @click="backBtn"
        >返回</van-button
      >
    </div>
  </div>
</template>
<script>
import { Notify } from "vant";
import { Dialog } from "vant";
import { getFamilyInfoOne } from "@/api/api";
export default {
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    getFamilyInfoOne({ id: this.$route.query.id }).then((res) => {
      if (res.data.data.productInfo == null) {
        this.$router.go(-1);
      } else {
        this.form = res.data.data;
      }
    });
  },
  methods: {
    backBtn() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0px;
}
.box {
  background: #fff;
  padding: 10px 20px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.display {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>