<template>
  <div class="root">
    <div class="head">
      <div class="title">
        <p>{{ isUpdate ? '修改体检信息' : '新增体检信息' }}</p>
      </div>
    </div>
    <div class="content">
      <van-form ref="formRef">
        <van-field v-model="certNo" label="身份证号" placeholder="请输入身份证号"
          :rules="[{ required: true, message: '请填写身份证号' }]" />
        <van-field v-model="name" label="姓名" placeholder="请输入姓名" :rules="[{ required: true, message: '请填写姓名' }]" />
        <van-field name="radio" label="是否已婚">
          <template #input>
            <van-radio-group v-model="marry" direction="horizontal">
              <van-radio name="2">未婚</van-radio>
              <van-radio name="1">已婚</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="mobile" label="手机号" placeholder="请输入手机号" :rules="[{ required: true, message: '请填写手机号' }]" />
        <van-field readonly clickable name="area" :value="value" label="地区选择" placeholder="点击选择省市区"
          @click="showArea = true" :rules="[{ required: true, message: '请选择省市区' }]" />
        <van-popup v-model="showArea" position="bottom">
          <van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
        </van-popup>
        <van-field v-model="address" label="详细地址" placeholder="请输入详细地址"
          :rules="[{ required: true, message: '请填写详细地址' }]" />
      </van-form>
    </div>
    <div class="footer">
      <div class="addUser" @click="addUser">
        <!-- <div class="icon">
          <van-icon name="add-o" size="24px" color="#fff" />
        </div> -->
        <div class="title">
          <p>确认</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AddUserInfo,QueryUserInfoByUserId,UpdateUserInfo } from "@/api/richApi";
import { getMenu } from "@/api/api";
import { Notify, Dialog } from "vant";
import { areaList } from '@vant/area-data';
export default {
  data() {
    return {
      icon: {},
      isUpdate: false,
      userId: '',
      formRef: null,
      //省市区
      value: '',
      //详细地址
      address: '',
      showArea: false,
      areaList,
      //1已婚 2未婚
      marry: '2',
      certNo: '',
      name: '',
      mobile: ''
    };
  },
  mounted() {
    this.getIcon();
    this.getUserId();
    this.getUserInfo();
  },
  methods: {
    async getIcon() {
      let res = await getMenu({ productCode: 'PC_CI52Y8XINYQM' })
      this.icon = res.data;
    },
    getUserId() {
      this.userId = this.$route.query.userId
      if (this.userId != null) {
        this.isUpdate = true
      }
    },
    //地址选择
    onConfirm(values) {
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
      this.showArea = false;
    },
    //确认按钮
    addUser() {
      if(this.isUpdate){
        Dialog.confirm({
          title: '确认修改吗',
        }).then(() => {
          UpdateUserInfo({
            userId:this.userId,
            address:this.value + '/' + this.address,
            marry:this.marry,
            certNo:this.certNo,
            name:this.name,
            mobile:this.mobile
          }).then((res) => {
            if (res.data.code == '00000') {
              Notify({ type: "success", message: '请求成功' });
              this.$router.replace({
                name: 'bookUserInfo',
              })
            } else {
              Notify({ type: "warning", message: res.data.msg })
            }
          })
        }).catch(() => { });
      }else{
        Dialog.confirm({
          title: '确认提交吗',
        }).then(() => {
          AddUserInfo({
            address:this.value + '/' + this.address,
            marry:this.marry,
            certNo:this.certNo,
            name:this.name,
            mobile:this.mobile
          }).then((res) => {
            if (res.data.code == '00000') {
              Notify({ type: "success", message: '请求成功' });
              this.$router.push({
                name: 'bookUserInfo',
              })
            } else {
              Notify({ type: "warning", message: res.data.msg })
            }
          })
        }).catch(() => { });
      }
    },
    //查询user信息
    async getUserInfo(){
      let res = await QueryUserInfoByUserId({ userId: this.userId })
      if(res.data.code == '00000'){
        this.name = res.data.data.name
        this.certNo = res.data.data.certNo
        this.marry = res.data.data.marry
        this.mobile = res.data.data.mobile
        let address = res.data.data.address;
        let parts = address.split('/');
        let firstPart = parts.slice(0, parts.length - 1).join('/');
        let secondPart = parts[parts.length - 1];
        this.value = firstPart;
        this.address = secondPart;
      }
    }
  },
};
</script>
<style lang="less" scoped>
.root {
  background-color: #F1F4FB;

  .head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgb(124, 120, 241), rgb(71, 116, 227));
    z-index: 10;

    .title {
      p {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .content {
    height: 70vh;
    margin-top: 5rem;
    padding-bottom: 6rem;
    padding-top: 4rem;
    background-color: #fff
  }

  .footer {
    width: 100%;
    height: 4rem;
    background-color: #fff;
    position: fixed;
    bottom: 0;

    .addUser {
      width: 60%;
      height: 2.5rem;
      margin: 0 auto;
      // background-color: rgb(70, 116, 231);
      background: linear-gradient(to bottom, rgb(124, 120, 241), rgb(71, 116, 227));
      // position: fixed;
      // top: 90%;
      // left: 0;
      // right: 0;
      margin-top: 1rem;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .title {
        p {
          color: #fff;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>