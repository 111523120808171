<template>
  <!-- 历史记录单人详情页面 -->
  <div class="home">
    <div class="center">
      <div class="box">
        <h3 class="text">基本信息</h3>
        <van-cell-group>
          <van-cell title="咨询人" :value="dataInfo.customerName" />
          <van-cell title="咨询时间" :value="dataInfo.createTime" />
          <van-cell title="病情描述" :value="dataInfo.consultInfo" />
          <van-cell title="咨询建议" :value="dataInfo.consultAdvice" />
        </van-cell-group>
      </div>
      <div class="box" v-if="fileList != null">
        <h3 class="text">病情照片</h3>
        <van-image
          v-for="(item, index) in this.fileList"
          :key="index"
          width="77"
          height="77"
          fit="contain"
          :src="item"
          style="margin: 3px"
          @click="imageBtn(index)"
        />
      </div>
    </div>
    <div class="footer">
      <div>
        <van-button
          @click="submitBtn"
          type="info"
          color="#3e77db"
          round
          block
          style="border-radius: 30px; padding: 0 10px"
          >返回</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { reportQueryOne } from "@/api/api";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      show: false,
      image: "",
      dataInfo: {},
      fileList: [],
    };
  },
  mounted() {
    const id = location.href.split("id=")[1];
    reportQueryOne({ id: id }).then((res) => {
      this.dataInfo = res.data.data;
      this.fileList = res.data.data.reportPicture;
    });
  },
  methods: {
    imageBtn(index) {
      ImagePreview({
        images: this.fileList,
        startPosition: index,
        closeable: true,
      });
    },
    submitBtn() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  .center {
    flex: 1;
    overflow: auto;
    .box {
      border-radius: 10px;
      background: #fff;
      margin: 10px 0;
      padding: 10px;
      box-sizing: border-box;
    }
  }
  .footer {
    width: 100%;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
  }
}
.text {
  padding: 0 10px;
  box-sizing: border-box;
}
/deep/.van-cell__title {
  flex: none;
  width: 30% !important;
}
/deep/.van-cell__value {
  width: 70% !important;
}
</style>