<template>
  <div class="root">
    <van-nav-bar title="我的卡片" left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-empty v-if="isEmpty" description="您还没有卡片" />
    <!-- 卡片列表 -->
    <div v-else class="card-list">
      <div class="card" v-for="card in cardList" :key="card.id">
        <!-- <img :src="card.cardUrl"> -->
        <img v-lazy="card.cardUrl">
        <div class="card-info">
          <div class="desc">名称：{{ card.cardName }}</div>
          <div class="desc">激活日期：{{ card.activationTime }}</div>
          <div class="desc">失效日期：{{ card.expiringTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryCardList } from "@/api/cardApi";
export default {
  data() {
    return {
      cardList: []
    };
  },
  computed:{
    isEmpty(){
      return this.cardList.length === 0
    }
  },
  mounted() {
    this.getCardList()
  },
  methods: {
    //左上角返回
    onClickLeft() {
      this.$router.back(-1)
    },
    //获取卡片列表
    async getCardList() {
      let res = await queryCardList({});
      this.cardList = res.data.data
    }
  },
}
</script>

<style lang="less" scoped>
.card-list {
  height: 100%;
  background-color: #F1F1F1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .card {
    box-sizing: border-box;
    margin: 8px;
    height: 240px;
    border-radius: 12px;
    box-shadow: 2px 1px 1px #bdcee0;

    img {
      overflow: hidden;
      height: 70%;
    }

    .card-info {
      background-color: #fff;
      display: flex;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      flex-direction: column;
      justify-content: space-evenly;
      height: 30%;
      margin-top: -3px;

      .desc {
        margin-left: 10px;
      }
    }
  }
}
</style>