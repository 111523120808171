<template>
  <div class="root">
    <div class="head">
      <div class="title">
        <p>体检人员信息管理</p>
      </div>
    </div>
    <div class="content" v-if="userList.length != 0">
      <div class="card" v-for="(item, index) in userList" :key="index">
        <p class="name">{{ item.name }} {{ item.mobile }}</p>
        <p class="address">{{ item.address }}</p>
        <div class="btn">
          <div class="delete" @click="deleteUser(item)">
            <p>删除</p>
          </div>
          <div class="edit" @click="editUser(item)">
            <p>修改</p>
          </div>
        </div>
      </div>
    </div>
    <div class="empty" v-else>
      <img :src="icon.empty" alt="">
    </div>
    <div class="footer">
      <div class="addUser" @click="addCheckUser">
        <div class="icon">
          <van-icon name="add-o" size="24px" color="#fff" />
        </div>
        <div class="title">
          <p>新增体检人员</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMenu } from "@/api/api";
import { Notify, Dialog } from "vant";
import { QueryUserInfo, DelUserInfo } from "@/api/richApi";
import { del } from "@vue/composition-api";
export default {
  data() {
    return {
      userList: [],
      icon: {}
    };
  },
  async mounted() {
    await this.getUserList();
    this.getIcon();
  },
  methods: {
    async getUserList() {
      let res = await QueryUserInfo({});
      if (res.data.code == '00000') {
        this.userList = res.data.data;
      } else {
        Notify({ type: "warning", message: res.data.msg });
      }
    },
    async getIcon() {
      let res = await getMenu({ productCode: 'PC_CI52Y8XINYQM' })
      this.icon = res.data;
    },
    deleteUser(item) {
      Dialog.confirm({
        title: '确认删除吗',
      }).then(() => {
        DelUserInfo({ ...item }).then((res) => {
          if (res.data.code == '00000') {
            Notify({ type: "success", message: '删除成功' });
            this.getUserList();
          }else if(res.data.code == '403'){
            Notify({ type: "warning", message: res.data.msg });
          }
        })
      }).catch(() => { });
    },
    addCheckUser() {
      this.$router.push({
        name: 'bookUserInfoEdit',
      })
    },
    editUser(item) {
      this.$router.push({
        name: 'bookUserInfoEdit',
        query: {
          userId: item.userId
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.root {
  background: #F1F4FB 100% 100%;
  height: 100vh;

  .head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgb(124, 120, 241), rgb(71, 116, 227));
    z-index: 10;

    .title {
      p {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .content {
    margin-top: 6rem;
    padding-bottom: 6rem;

    .card {
      width: 90%;
      height: 8rem;
      margin: 0 auto;
      border-radius: 10px;
      // height: 10rem;
      background-color: #fff;
      padding-left: 1rem;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 1rem;
      position: relative;

      .name {
        font-size: 18px;
        font-weight: bold;
      }

      .btn {
        position: absolute;
        bottom: 5px;
        right: 2rem;
        display: flex;

        .delete,
        .edit {
          margin-left: 1rem;
          border-radius: 100px;
          width: 4rem;
          height: 25px;
          // position: relative;
          background: linear-gradient(to bottom, rgb(124, 120, 241), rgb(71, 116, 227));

          p {
            color: #fff;
            text-align: center;
            line-height: 1px;
          }
        }
      }
    }
  }

  .empty {
    img {
      width: 100%;
      margin-top: 15rem;
    }
  }

  .footer {
    width: 100%;
    height: 4rem;
    background-color: #fff;
    position: fixed;
    bottom: 0;

    .addUser {
      width: 60%;
      height: 2.5rem;
      margin: 0 auto;
      // background-color: rgb(70, 116, 231);
      background: linear-gradient(to bottom, rgb(124, 120, 241), rgb(71, 116, 227));
      // position: fixed;
      // top: 90%;
      // left: 0;
      // right: 0;
      margin-top: 1rem;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .title {
        p {
          color: #fff;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>