<template>
  <!-- 健康档案里的基础健康信息 -->
  <van-form validate-first>
    <div class="home">
      <div class="center">
        <div class="formBox">
          <van-field
            readonly
            clickable
            name="name"
            :value="form.name"
            label="健康档案归属人"
            label-width="120px"
            placeholder="请选择健康档案归属人"
            right-icon="arrow"
            @click="nameCheck"
          />
          <van-popup v-model="nameShow" position="bottom">
            <van-picker
              show-toolbar
              :columns="nameSelect"
              @confirm="nameConfirm"
              @cancel="nameShow = false"
            >
              <template #option="option">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <div>{{ option.name }}</div>
                </div>
              </template>
            </van-picker>
          </van-popup>
        </div>
        <div class="formBox">
          <h3 class="title">基本信息</h3>
          <van-field
            v-model="form.age"
            label="年龄"
            name="age"
            placeholder="请输入年龄"
          />
          <van-field
            v-model="form.height"
            label="身高"
            name="height"
            type="number"
            placeholder="请输入身高(CM)"
          />
          <van-field
            v-model="form.weight"
            label="体重"
            name="weight"
            type="number"
            placeholder="请输入体重(KG)"
          />
          <van-field
            readonly
            clickable
            name="bloodGroup"
            :value="form.bloodGroup"
            label="血型"
            placeholder="请选择血型"
            right-icon="arrow"
            @click="bloodGroupShow = true"
          />
          <van-popup v-model="bloodGroupShow" position="bottom">
            <van-picker
              show-toolbar
              :columns="bloodGroupSelect"
              @confirm="bloodGroupConfirm"
              @cancel="bloodGroupShow = false"
            >
              <template #option="option">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <div>{{ option.name }}</div>
                </div>
              </template>
            </van-picker>
          </van-popup>
        </div>
        <div class="formBox">
          <h3 class="title">个人信息</h3>
          <van-field
            readonly
            clickable
            name="marriage"
            :value="form.marriage"
            label="婚姻状况"
            placeholder="请选择婚姻状况"
            right-icon="arrow"
            @click="marriageShow = true"
          />
          <van-popup v-model="marriageShow" position="bottom">
            <van-picker
              show-toolbar
              :columns="marriageSelect"
              @confirm="marriageConfirm"
              @cancel="marriageShow = false"
            >
              <template #option="option">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <div>{{ option.name }}</div>
                </div>
              </template>
            </van-picker>
          </van-popup>
          <van-field
            readonly
            clickable
            name="birth"
            :value="form.birth"
            label="生育状态"
            placeholder="请选择生育状态"
            right-icon="arrow"
            @click="birthShow = true"
          />
          <van-popup v-model="birthShow" position="bottom">
            <van-picker
              show-toolbar
              :columns="birthSelect"
              @confirm="birthConfirm"
              @cancel="birthShow = false"
            >
              <template #option="option">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <div>{{ option.name }}</div>
                </div>
              </template>
            </van-picker>
          </van-popup>
        </div>
        <div class="formBox">
          <h3 class="title">个人习惯</h3>
          <van-field
            readonly
            clickable
            name="smoke"
            :value="form.smoke"
            label="吸烟史"
            placeholder="请选择吸烟史"
            right-icon="arrow"
            @click="smokeShow = true"
          />
          <van-popup v-model="smokeShow" position="bottom">
            <van-picker
              show-toolbar
              :columns="smokeSelect"
              @confirm="smokeConfirm"
              @cancel="smokeShow = false"
            >
              <template #option="option">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <div>{{ option.name }}</div>
                </div>
              </template>
            </van-picker>
          </van-popup>
          <van-field
            readonly
            clickable
            name="drink"
            :value="form.drink"
            label="饮酒史"
            placeholder="请选择饮酒史"
            right-icon="arrow"
            @click="drinkShow = true"
          />
          <van-popup v-model="drinkShow" position="bottom">
            <van-picker
              show-toolbar
              :columns="drinkSelect"
              @confirm="drinkConfirm"
              @cancel="drinkShow = false"
            >
              <template #option="option">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <div>{{ option.name }}</div>
                </div>
              </template>
            </van-picker>
          </van-popup>
        </div>
      </div>
      <div class="footer">
        <div>
          <van-button
            @click="submitBtn"
            :loading="loading"
            :disabled="loading"
            type="info"
            color="#3e77db"
            round
            block
            style="border-radius: 30px; padding: 0 40px"
            >提交</van-button
          >
        </div>
      </div>
    </div>
  </van-form>
</template>
  <script>
import { getSelect, getFamilyInfoOne, getFamilyAddEdit } from "@/api/api";
import { Notify } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      loading: false,
      form: {
        id: "",
        name: "",
        age: "",
        height: "",
        weight: "",
        marriage: "",
        birth: "",
        smoke: "",
        drink: "",
      },
      nameSelect: [],
      bloodGroupSelect: [],
      marriageSelect: [],
      birthSelect: [],
      smokeSelect: [],
      drinkSelect: [],
      nameShow: false,
      bloodGroupShow: false,
      marriageShow: false,
      birthShow: false,
      smokeShow: false,
      drinkShow: false,
    };
  },
  mounted() {
    getSelect().then((res) => {
      this.nameSelect = res.data.data.customerList;
      this.bloodGroupSelect = res.data.data.bloodGroup;
      this.marriageSelect = res.data.data.marriage;
      this.birthSelect = res.data.data.birth;
      this.smokeSelect = res.data.data.smoke;
      this.drinkSelect = res.data.data.drink;
    });
  },
  methods: {
    nameCheck() {
      if (this.nameSelect == "") {
        this.$router.push({ name: "mine/familyInfo" });
      } else {
        this.nameShow = true;
      }
    },
    //健康档案归属人
    nameConfirm(value) {
      this.form.name = value.name;
      this.form.id = value.id;
      getFamilyInfoOne({ id: value.id }).then((res) => {
        this.form = res.data.data;
      });
      this.nameShow = false;
    },
    //血型
    bloodGroupConfirm(value) {
      this.form.bloodGroup = value.name;
      this.bloodGroupShow = false;
    },
    //婚姻
    marriageConfirm(value) {
      this.form.marriage = value.name;
      this.marriageShow = false;
    },
    //生育
    birthConfirm(value) {
      this.form.birth = value.name;
      this.birthShow = false;
    },
    //吸烟
    smokeConfirm(value) {
      this.form.smoke = value.name;
      this.smokeShow = false;
    },
    //饮酒
    drinkConfirm(value) {
      this.form.drink = value.name;
      this.drinkShow = false;
    },
    submitBtn() {
      const ageCheck = /^(?:[1-9][0-9]?|1[01][0-9]|120)$/;
      if (this.form.name == "") {
        Toast("请选择健康档案归属人");
      } else if (ageCheck.test(this.form.age) == false) {
        Toast("请输入正确的年龄");
      } else if (
        this.form.height < 50 ||
        this.form.height > 250 ||
        this.form.height == ""
      ) {
        Toast("请输入正确的身高");
      } else if (
        this.form.weight < 10 ||
        this.form.weight > 200 ||
        this.form.weight == ""
      ) {
        Toast("请输入正确的体重");
      } else if (this.form.blood == "") {
        Toast("请选择血型");
      } else {
        this.loading = true;
        getFamilyAddEdit(this.form).then((res) => {
          if (res.data.code == "00000") {
            Notify({ type: "success", message: "提交成功" });
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          } else {
            Notify({ type: "danger", message: res.data.msg });
          }
        });
      }
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  },
};
</script>
  <style lang="less" scoped>
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .center {
    padding: 10px;
    box-sizing: border-box;
    flex: 1;
    overflow: auto;
  }
  .footer {
    width: 100%;
    height: 65px;
    padding: 0 10px;
    box-sizing: border-box;
  }
}
.formBox {
  margin: 10px 0;
  border-radius: 10px;
  background: #fff;
  padding: 5px;
  box-sizing: border-box;
}
.title {
  padding: 0 10px;
  box-sizing: border-box;
}
</style>