<template>
  <div class="root" v-cloak>
    <!-- 累计金额、交易笔数展示面板 -->
    <div class="top" :style="{ backgroundImage: `url(${homeBack})` }">
      <van-tabs @click="onTabClick" background="rgb(255,255,255,0)" color="#fff" title-inactive-color="#C0C0C0"
        title-active-color="#fff" line-height="2" v-model="salesCode" :before-change="beforeChange">
        <van-tab color="#fff" v-for="item in agentList" :name="item.name" :title="item.title" ::key="item.id">
          <div class="tabContent">
            <div class="date">
              <p>{{ nowDate }}</p>
            </div>
            <div class="num">
              <p>共成交{{ item.orderNum }}笔，合计</p>
            </div>
            <div class="amount">
              <p>￥ {{ item.totalMoney }}</p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!-- 订单构成 -->
    <div class="middle-content" v-if="orderViewData.length != 0">
      <!-- 饼状图 -->
      <div class="echart" id="myChart" :style="myChartStyle"></div>
      <div class="orderView">
        <div class="title">
          <p>订单构成</p>
        </div>
        <div class="content" v-for="item in orderViewData" :key="item.productType">
          <div class="orderTypeIcon">
            <img :src="item.productType == '0' ? policyIcon : (item.productType == '1' ? checkIcon : serviceIcon)">
          </div>
          <div class="name">
            <p>{{ item.productName }}</p>
          </div>
          <div class="progress">
            <Progress :width="140" :progress="item.percentage" :strokeWidth="6" :showInfo="true"
              :bgColor="item.color" />
          </div>
          <div class="amount">￥{{ item.totalAmount }}</div>
          <div class="goIcon" @click="queryOrderDetail(item)"><van-icon name="arrow" /></div>
        </div>
      </div>
      <div class="logout" @click="logout">
        <p>退出登录</p>
      </div>
      <van-overlay :show="overLayShow" z-index="999">
        <div class="wrapper">
          <div class="block">
            <van-loading color="#1989fa" v-if="waitShow">加载中...</van-loading>
          </div>
        </div>
      </van-overlay>
      <van-notify v-model="show" type="success">
        <van-icon name="bell" style="margin-right: 4px;" />
        <span>退出成功</span>
      </van-notify>
    </div>
    <!-- 订单为空展示 -->
    <div class="empty" v-if="orderViewData.length == 0">
      <img :src="noOrderImg">
      <div class="text">
        <p>暂无订单</p>
      </div>
      <div class="logout" @click="logout">
        <p>退出登录</p>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>
<script>

import Progress from '@/components/Progress'
import { getMenu, querySales } from "@/api/api";
import { Dialog } from 'vant';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
// 引入标题，提示框，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent
} from 'echarts/components';
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent
]);

export default {
  data() {
    return {
      show: false,
      //背景图片
      homeBack: '',
      //保险logo
      policyIcon: '',
      //健康服务logo
      serviceIcon: '',
      //体检logo
      checkIcon: '',
      //控制加载中显示
      overLayShow: true,
      waitShow: true,
      //销售人员code
      salesCode: '',
      nowDate: '',
      agentList: [],
      //当前订单构成数据
      orderViewData: [],
      //空状态图片
      noOrderImg: '',
      myChart: {},
      pieData: [],
      pieName: [],
      myChartStyle: { width: "100%", height: "55vh" }
    };
  },
  methods: {
    //查看订单详情
    queryOrderDetail(item) {
      console.log(item);
      console.log(this.salesCode);
      this.$router.push({
        name: 'orderDetail',
        query: {
          salesCode: this.salesCode,
          productType: item.productType
        }
      })
    },
    beforeChange(val) {
      const element = this.agentList.find(obj => obj.name == val)
      this.orderViewData = element.orderViewResponseList
    },
    //标签栏单击
    onTabClick(name, title) {
      this.salesCode = name;
    },
    //获取菜单信息
    async getMenuInfo() {
      let res = await getMenu({ productCode: 'PC_CDNMQNNIllDB' })
      this.homeBack = res.data.homeBack
      this.policyIcon = res.data.policyIcon
      this.serviceIcon = res.data.healthServiceIcon
      this.checkIcon = res.data.healthCheckIcon
      this.noOrderImg = res.data.noOrder
    },
    async querySalesList(saleName) {
      this.waitShow = true;
      this.overLayShow = true;
      let res = await querySales({ keywords: saleName })
      if (res.data.success) {
        this.agentList = res.data.data;
        this.orderViewData = res.data.data[0].orderViewResponseList
        this.pieData = res.data.data[0].valueNameList
        this.pieData.forEach(p => {
          this.pieName.push(p.name)
        })
      }
      this.waitShow = false;
      this.overLayShow = false;
    },
    getNowDate() {
      let date = new Date(),
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate() // 获取当前日(1-31)
      if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0

      this.nowDate = `${year}年${month}月${strDate}日`
    },
    logout() {
      Dialog.confirm({
        message: '确认退出登录？',
      })
        .then(() => {
          // on confirm
          this.show = true
          localStorage.removeItem('bookToken')
          setTimeout(() => {
            this.$router.push({ name: 'bookLogin' })
          }, 500)
        })
        .catch(() => {
          // on cancel
        });
    },
    //=========================================Echarts图表=========================================================
    initEcharts() {
      // 饼图
      const option = {
        legend: {
          data: this.pieName,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          align: 'left',
          padding: [0, 30, 0, 10]
        },
        title: {
          // 设置饼图标题，位置设为顶部居中
          text: "销售额占比",
          textStyle: {
            //文字颜色
            color: '#000',
            //字体风格,'normal','italic','oblique'
            // fontStyle: 'normal',
            //字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
            fontWeight: 'bold',
            //字体系列
            // fontFamily: 'sans-serif',
            //字体大小
            fontSize: 19
          },
          top: "5%",
          left: "left",
          padding: [0, 0, 0, 20]
        },
        series: [
          {
            right: 70,
            type: "pie",
            label: {
              show: true,
              // formatter: "{b} : {c} ({d}%)" // b代表名称，c代表对应值，d代表百分比
              formatter: "{b} : ({d}%)" // b代表名称，c代表对应值，d代表百分比
            },
            // radius: "50%", //饼图半径
            radius: ['30%', '50%'],
            padAngle: 5,
            itemStyle: {
              borderRadius: 10
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                fontWeight: 'bold'
              }
            },
            data: this.pieData
          }
        ]
      }
      this.myChart = echarts.init(document.getElementById("myChart"));
      this.myChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    }
  },
  async mounted() {
    this.getMenuInfo()
    await this.querySalesList()
    this.getNowDate()
    this.initEcharts()
  },
  components: {
    Progress
  }
}
</script>
<style lang="less" scoped>
.root {
  background-color: rgb(241, 244, 251);

  .top {
    background-size: cover;
    height: 20rem;

    .van-tabs {
      top: 20px;

      .van-tab__text span {
        color: #fff;
      }
    }

    .tabContent {
      margin-top: 30px;
      margin-left: 20px;

      .date {
        margin-bottom: 30px;

        p {
          font-size: 18px;
          color: #fff;
        }
      }

      .num {
        p {
          color: #fff;
          font-size: 14px;
        }
      }

      .amount {
        p {
          font-weight: bold;
          color: #fff;
          font-size: 30px;
        }

        ::first-letter {
          font-size: 14px;
          font-weight: lighter;
        }
      }
    }
  }

  .middle-content {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #fff;
    height: 50rem;
    margin-top: -4rem;

    .orderView {
      width: 90%;
      margin: 0 auto;
      display: flex;
      gap: 1rem;
      flex-direction: column;
      justify-content: baseline;
      margin-top: -3rem;

      .title {
        p {
          font-size: 19px;
          font-weight: bold;
        }
      }

      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .orderTypeIcon {
          width: 20px;

          img {
            height: 20px;
          }
        }

        .progress {
          padding-top: 6px;
          margin-right: 15px;
        }

        .name {
          margin-left: 10px;
          width: 60px;

          p {
            font-size: 14px;
          }
        }

        .amount {
          width: 70px;
        }

        .goIcon {
          width: 10px;
          margin-left: 1rem;
        }
      }
    }

    .logout {
      width: 50%;
      margin: 0 auto;
      background: linear-gradient(to right, rgb(140, 121, 249), rgb(59, 50, 253));
      height: 40px;
      border-radius: 30px;
      margin-top: 14rem;

      p {
        font-size: 14px;
        color: #fff;
        text-align: center;
        line-height: 40px;
      }
    }
  }

  .empty {
    margin-top: -4rem;
    z-index: 100;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #fff;
    overflow: hidden;

    img {
      width: 100vw;
    }

    .text {
      p {
        font-size: 24px;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        text-align: center;
      }
    }

    .logout {
      width: 60%;
      margin: 0 auto;
      background: linear-gradient(to right, rgb(140, 121, 249), rgb(59, 50, 253));
      height: 40px;
      border-radius: 30px;
      margin-top: 14rem;

      p {
        font-size: 14px;
        color: #fff;
        text-align: center;
        line-height: 40px;
      }
    }
  }
}

[v-cloak] {
  display: none !important
}
</style>