<template>
  <div class="root">
    <div class="main" :style="{ backgroundImage: `url(${loginBack})` }">
      <div class="header">
        <div class="logo">
          <img :src="companyLogo" alt="">
        </div>
        <div class="title">
          <p>您好，欢迎登录中弘润景</p>
        </div>
      </div>
      <div class="input">
        <div class="account">
          <van-field v-model="account" clearable placeholder="请输入账号名称" />
        </div>
        <div class="password">
          <van-field v-model="password" clearable type="password" placeholder="请输入密码" />
        </div>
      </div>
      <div class="loginBtn">
        <van-button @click="login" color="rgb(70,115,230)" round type="info" size="large">登录</van-button>
      </div>
    </div>
    <div class="middle">
    </div>
  </div>
</template>
<script>
import { getMenu, bookLogin } from '@/api/api'
import { Notify } from "vant";
export default {
  data() {
    return {
      companyLogo: '',
      loginBack: '',
      //账号密码
      account: '',
      password: ''
    }
  },
  methods: {
    //获取菜单信息
    async getMenuInfo() {
      let res = await getMenu({ productCode: 'PC_CDNMQNNIllDB' })
      this.companyLogo = res.data.companyLogo
      this.loginBack = res.data.loginBack
    },
    //非空校验
    checkNull() {
      if (this.account == '') {
        this.$toast.fail('请输入账号名称')
        return false;
      } else if (this.password == '') {
        this.$toast.fail('请输入密码')
        return false;
      }
      return true;
    },
    //登录
    async login() {
      if (!this.checkNull()) {
        return
      }
      let res = await bookLogin({
        account: this.account,
        password: this.password
      })
      console.log(res);
      if (res.data.success) {
        localStorage.setItem('bookToken', res.data.data.token)
        this.$router.push({
          name: 'bookHome'
        })
      } else if (res.data.code == "403") {
        Notify({ type: "danger", message: res.data.msg });
      }
    }
  },
  mounted() {
    this.getMenuInfo()
  },
}
</script>
<style lang="less" scoped>
.root {
  background-color: #fff;
  height: 100vh;
}

.main {
  background-color: rgb(255, 255, 255);
  background-size: 80% 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    margin-top: 80px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      width: 50px;

      img {
        width: 50px;
      }
    }

    .title p {
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }

  .input {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 80%;

    .account,
    .password {
      height: 50px;
    }

    .van-cell,
    .van-field {
      border-radius: 35px;
    }
  }

  .loginBtn {
    margin-top: 40px;
    width: 80%;
  }
}

.middle {
  height: 10vh;
  background: linear-gradient(to bottom, rgb(251, 250, 255), #fff);
}
</style>