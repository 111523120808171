<template>
  <div class="root">
    <div class="head">
      <div class="avatar">
        <img :src="icon.companyLogo">
      </div>
      <div class="mobile">
        <p>{{ mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2') }}</p>
      </div>
    </div>
    <div class="content">
      <div class="title">
        <p>体检服务</p>
      </div>
      <div class="line">
        <van-divider />
      </div>
      <div class="main">
        <div class="item" @click="myCheck">
          <div class="icon">
            <img :src="icon.myCheck">
          </div>
          <div class="text">
            <p>我的体检</p>
          </div>
        </div>
        <div class="item" @click="viewInstitutions">
          <div class="icon">
            <img :src="icon.checkIns">
          </div>
          <div class="text">
            <p>体检机构信息</p>
          </div>
        </div>
        <div class="item" @click="viewMyReport">
          <div class="icon">
            <img :src="icon.checkRecord">
          </div>
          <div class="text">
            <p>我的报告</p>
          </div>
        </div>
        <div class="item" @click="goUserInfo">
          <div class="icon">
            <img :src="icon.bookInfo">
          </div>
          <div class="text">
            <p>预约信息管理</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="title">
        <p>其他</p>
      </div>
      <div class="line">
        <van-divider />
      </div>
      <div class="main">
        <div class="item" @click="goQuestion">
          <div class="icon">
            <img :src="icon.question">
          </div>
          <div class="text">
            <p>常见问题</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Dialog } from 'vant';
import { getMenu } from "@/api/api";
import { queryCheckUserExist } from "@/api/richApi";
export default {
  data() {
    return {
      mobile: '',
      icon: {}
    };
  },
  mounted() {
    this.getMobile();
    this.getIcon();
    this.checkUserExists();
  },
  methods: {
    async getIcon() {
      let res = await getMenu({ productCode: 'PC_CI52Y8XINYQM' })
      this.icon = res.data;
    },
    getMobile() {
      this.mobile = localStorage.getItem('phone')
    },
    myCheck() {
      this.$router.push({
        name: 'myCheck'
      })
    },
    viewInstitutions() {
      this.$router.push({
        name: 'institutionView'
      })
    },
    goUserInfo(){
      this.$router.push({
        name:'bookUserInfo'
      })
    },
    viewMyReport(){
      this.$router.push({
        name:'myReport'
      })
    },
    goQuestion(){
      this.$router.push({
        name:'richQuestions'
      })
    },
    async checkUserExists(){
      let res = await queryCheckUserExist()
      if(res.data.code == '00000'){
        if(res.data.data){
          //不存在体检人
          Dialog({ message: '您还未添加体检人员信息，请在 【预约信息管理】 中新增体检人员' });
        }
      }
    }
  },
};
</script>
<style lang="less" scoped>
.root {
  display: flex;
  flex-direction: column;
  background-color: #F1F4FB;
  gap: 2rem;

  .head {
    display: flex;
    align-items: center;
    height: 14rem;
    background: linear-gradient(to bottom, rgb(124, 120, 241), rgb(71, 116, 227));
    padding-left: 1.5rem;

    .avatar {
      margin-top: -4rem;
      img {
        height: 4rem;
      }
    }

    .mobile {
      margin-top: -4rem;
      margin-left: 1rem;
      background-color: #fff;
      border-radius: 100px;
      width: 10rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .content {
    background-color: #fff;
    width: 95%;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: -6rem;
    height: 10rem;

    .title {
      height: 0.5rem;

      p {
        line-height: 0.5rem;
        margin-left: 10px;
        font-size: 20px;
        font-weight: bold;
      }
    }

    .main {
      display: flex;
      margin-top: 1rem;
      justify-content: space-around;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          img {
            height: 2rem;
          }
        }
      }
    }
  }

  .footer {
    background-color: #fff;
    width: 95%;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: -1rem;
    height: 10rem;

    .title {
      height: 0.5rem;

      p {
        line-height: 0.5rem;
        margin-left: 10px;
        font-size: 20px;
        font-weight: bold;
      }
    }

    .main {
      // display: flex;
      // justify-content: space-around;
      margin-top: 1rem;
      margin-left: 1.5rem;
      .item {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        width: 4rem;
        .icon {
          img {
            height: 2rem;
          }
        }
        .text{
          p{
            margin-left: -12px;
          }
        }
      }
    }
  }
}
</style>