<template>
  <!-- 体检记录 -->
  <van-form validate-first>
    <div class="home">
      <div class="center">
        <div class="formBox">
          <h3 class="title">体检信息</h3>
          <van-field
            readonly
            clickable
            required
            name="name"
            :value="form.name"
            label="就诊人"
            placeholder="请选择就诊人"
            right-icon="arrow"
            @click="nameCheck"
          />
          <van-popup v-model="nameShow" position="bottom">
            <van-picker
              show-toolbar
              :columns="nameSelect"
              @confirm="nameConfirm"
              @cancel="nameShow = false"
            >
              <template #option="option">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <div>{{ option.name }}</div>
                </div>
              </template>
            </van-picker>
          </van-popup>
          <van-field
            readonly
            clickable
            name="visitTime"
            :value="form.visitTime"
            label="体检时间"
            right-icon="arrow"
            placeholder="请选择体检时间"
            @click="visitTimeShow = true"
          />
          <van-popup v-model="visitTimeShow" position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              :min-date="minDate"
              :max-date="maxDate"
              :columns-order="['year', 'month', 'day']"
              :formatter="formatter"
              @confirm="visitTimeConfirm"
              @cancel="visitTimeShow = false"
            />
          </van-popup>
          <van-field
            v-model="form.checkupOrg"
            label="体检机构"
            name="checkupOrg"
            placeholder="请输入体检机构"
          />
          <van-field
            v-model="form.checkupReport"
            rows="2"
            :autosize="{ maxHeight: 70, minHeight: 70 }"
            label="报告名称"
            type="textarea"
            maxlength="50"
            placeholder="如：张三的2023体检报告"
            show-word-limit
          />
        </div>
        <div class="formBox">
          <h3 class="title">体检照片</h3>
          <div style="margin: 10px">
            点击可查看原图
            <span style="color: #3e77db">{{ imageNum }}/20</span>
          </div>
          <van-uploader
            style="padding: 10px"
            v-model="fileList"
            :after-read="clzpAfterRead"
            @delete="deleteClzp"
            :max-count="20"
            multiple
          >
          </van-uploader>
        </div>
      </div>

      <div class="footer">
        <div>
          <van-button
            @click="submitBtn"
            :loading="loading"
            :disabled="loading"
            type="info"
            color="#3e77db"
            round
            block
            style="border-radius: 30px; padding: 0 40px"
            >提交</van-button
          >
        </div>
      </div>
    </div>
  </van-form>
</template>
  <script>
import { getSelect, uploadDataInfo } from "@/api/api";
import { Notify } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      loading: false,
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2051, 0, 1),
      currentDate: new Date(),
      form: {
        customerRecordId: "",
        name: "",
        recordType: "3",
        visitTime: "",
        checkupOrg: "",
        checkupReport: "",
      },
      fileList: [], //上传的文件列表
      clzpfilePath: "", //删除所需参数
      imageNum: 0,
      nameSelect: [],
      nameShow: false,
      visitTimeShow: false,
    };
  },
  mounted() {
    getSelect().then((res) => {
      this.nameSelect = res.data.data.customerList;
    });
  },
  methods: {
    nameCheck() {
      if (this.nameSelect == "") {
        this.$router.push({ name: "mine/familyInfo" });
      } else {
        this.nameShow = true;
      }
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      return val;
    },
    nameConfirm(value) {
      this.form.name = value.name;
      this.form.customerRecordId = value.id;
      this.nameShow = false;
    },
    visitTimeConfirm(value) {
      var date = new Date(value);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      this.form.visitTime = y + "-" + m + "-" + d;
      this.visitTimeShow = false;
    },
    //照片上传事件方法
    clzpAfterRead(file) {
      this.imageNum = this.fileList.length;
    },
    //照片删除事件方法
    deleteClzp() {
      this.imageNum = this.fileList.length;
    },
    submitBtn() {
      if (this.form.name == "") {
        Toast("请选择就诊人");
      } else {
        this.loading = true;
        uploadDataInfo({ dataInfo: this.form, fileList: this.fileList }).then(
          (res) => {
            if (res.data.code == "00000") {
              Notify({ type: "success", message: "提交成功" });
              this.loading = false;
              this.$router.go(-1);
            } else {
              Notify({ type: "danger", message: res.data.msg });
              this.loading = false;
            }
          }
        );
      }
    },
  },
};
</script>
  <style lang="less" scoped>
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .center {
    padding: 15px;
    box-sizing: border-box;
    flex: 1;
    overflow: auto;
  }
  .footer {
    width: 100%;
    height: 65px;
    padding: 0 10px;
    box-sizing: border-box;
  }
}
.title {
  padding: 0 10px;
  box-sizing: border-box;
}
.formBox {
  margin: 10px 0;
  border-radius: 10px;
  background: #fff;
  padding: 5px;
  box-sizing: border-box;
}
</style>