export default function getProvinceData() {
  return [
    { text: "全部省份", value: "全部省份" },
    { text: "北京市", value: "北京市" },
    { text: "天津市", value: "天津市" },
    { text: "河北省", value: "河北省" },
    { text: "山西省", value: "山西省" },
    { text: "内蒙古自治区", value: "内蒙古自治区" },
    { text: "辽宁省", value: "辽宁省" },
    { text: "吉林省", value: "吉林省" },
    { text: "黑龙江省", value: "黑龙江省" },
    { text: "上海市", value: "上海市" },
    { text: "江苏省", value: "江苏省" },
    { text: "浙江省", value: "浙江省" },
    { text: "安徽省", value: "安徽省" },
    { text: "福建省", value: "福建省" },
    { text: "江西省", value: "江西省" },
    { text: "山东省", value: "山东省" },
    { text: "河南省", value: "河南省" },
    { text: "湖北省", value: "湖北省" },
    { text: "湖南省", value: "湖南省" },
    { text: "广东省", value: "广东省" },
    { text: "广西壮族自治区", value: "广西壮族自治区" },
    { text: "海南省", value: "海南省" },
    { text: "重庆市", value: "重庆市" },
    { text: "四川省", value: "四川省" },
    { text: "贵州省", value: "贵州省" },
    { text: "云南省", value: "云南省" },
    { text: "西藏自治区", value: "西藏自治区" },
    { text: "陕西省", value: "陕西省" },
    { text: "甘肃省", value: "甘肃省" },
    { text: "青海省", value: "青海省" },
    { text: "宁夏回族自治区", value: "宁夏回族自治区" },
    { text: "新疆维吾尔自治区", value: "新疆维吾尔自治区" },
    { text: "台湾", value: "台湾" },
    { text: "香港特别行政区", value: "香港特别行政区" },
    { text: "澳门特别行政区", value: "澳门特别行政区" }
  ]
}