<template>
  <div class="container">
    <img
      style="width: 100%; height: auto"
      @click="imgUrlBtn"
      :src="titlePicture"
    />
    <div style="padding: 0 10px; box-sizing: border-box; margin-top: 10px">
      <div class="tabBox">
        <van-grid v-if="num <= 3" :column-num="num" :border="false">
          <van-grid-item
            v-for="(item, index) in menuData"
            :key="index"
            @click="menuDataBtn(item)"
          >
            <img
              :src="item.picture"
              alt
              style="margin-bottom: 15px; width: 50%; height: auto"
            />
            {{ item.label }}
          </van-grid-item>
        </van-grid>
        <van-grid v-if="num > 3" :column-num="num" :border="false">
          <van-grid-item
            v-for="(item, index) in menuData"
            :key="index"
            @click="menuDataBtn(item)"
          >
            <img
              :src="item.picture"
              alt
              style="margin-bottom: 15px; width: 65%; height: auto"
            />
            {{ item.label }}
          </van-grid-item>
        </van-grid>
      </div>
      <div class="recommendBox" style="padding: 5px; box-sizing: border-box">
        <p class="textTitle" style="padding-left: 5px; margin-top: 10px">产品推荐</p>
        <van-row style="text-align: center">
          <van-col
            span="12"
            v-for="(item, index) in centerImg"
            :key="index"
            style="padding: 2px; box-sizing: border-box"
          >
            <img :src="item.picture" alt @click="imgBtn(item)" />
          </van-col>
        </van-row>
      </div>
      <div class="recommendBox">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
          "
        >
          <div class="textTitle" style="margin-top: 13px">产品推文</div>
          <div style="color: #666; position: relative; margin-right: 10px">更多 ></div>
        </div>
        <div>
          <div
            class="displayBox"
            style="border-bottom: 1px solid #ccc; height: 120px"
            v-for="(item, index) in recommendData"
            :key="index"
            @click="productTweetsBtn(item)"
          >
            <div style="width: 60%; margin-bottom: 20px">
              <div style="font-weight: bold; font-size: 16px; margin: 10px 0">
                {{ item.title }}
              </div>
            </div>
            <div style="width: 38%; padding-right: 10px">
              <img style="width: 100%; padding: 10px" :src="item.img" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 3rem"></div>
  </div>
</template>

<script>
import { Notify } from "vant";
import { getMenu, quickLogin } from "@/api/api";
import channelStore from "@/store/module/channelStore";
export default {
  name: "home",
  components: {},
  data() {
    return {
      num: "",
      menuData: [],
      centerImg: [],
      titlePicture:"",
      titlePictureUrl: "",
      recommendData: [
        {
          title: "肺结节离“癌”有多远？",
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E7%BC%96%E7%BB%84%2012%403x.png",
          imageUrl:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BA%A7%E5%93%81/%E4%BA%A7%E5%93%81%E6%8E%A8%E6%96%87%E5%86%85%E5%AE%B9/%E8%82%BA%E7%BB%93%E8%8A%82%E6%8E%A8%E6%96%87.jpg",
        },
        {
          title: "肺结节患者调整生活方式有哪些？",
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BA%A7%E5%93%81/%E4%BA%A7%E5%93%81%E6%8E%A8%E6%96%87%E5%86%85%E5%AE%B9/%E4%BA%A7%E5%93%81%E6%8E%A8%E6%96%872.png",
          imageUrl:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BA%A7%E5%93%81/%E4%BA%A7%E5%93%81%E6%8E%A8%E6%96%87%E5%86%85%E5%AE%B9/%E4%BA%A7%E5%93%81%E6%8E%A8%E6%96%87%E8%AF%A6%E6%83%85%E5%9B%BE2.jpg",
        },
        {
          title: "肺结节患者饮食改善",
          img:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BA%A7%E5%93%81/%E4%BA%A7%E5%93%81%E6%8E%A8%E6%96%87%E5%86%85%E5%AE%B9/%E4%BA%A7%E5%93%81%E6%8E%A8%E6%96%873.png",
          imageUrl:
            "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BA%A7%E5%93%81/%E4%BA%A7%E5%93%81%E6%8E%A8%E6%96%87%E5%86%85%E5%AE%B9/%E4%BA%A7%E5%93%81%E6%8E%A8%E6%96%87%E8%AF%A6%E6%83%85%E5%9B%BE3.jpg",
        },
      ],
    };
  },
  created() {
    let channelCode = this.$router.history.current.query.channelCode;
    if(!channelCode){
      channelCode = this.$store.state.channelStore.channelCode
    }
    localStorage.setItem("channelCode",channelCode)
    this.$store.commit('channelStore/saveChannelCode',channelCode)
    // 判断是否从servicePage跳过来
    if (!localStorage.getItem("token")) {
      if (window.location.hash) {
        if (window.location.href.split("#login=")[1].split("?")[0]) {
          Notify({ type: "warning", message: "请先输入手机号" });
          setTimeout(() => {
            this.$router.push({ name: "loginPage" });
          }, 800);
        }
      }
    }
    if (window.location.search.includes("equityCode")) {
      sessionStorage.setItem(
        "equityCode",
        window.location.search.split("equityCode=")[1].split("&channelCode=")[0]
      );
      sessionStorage.setItem(
        "channelCode",
        window.location.search.split("&channelCode=")[1]
      );
    } else {
      if (window.location.hash) {
        sessionStorage.setItem(
          "equityCode",
          window.location.href.split("equityCode=")[1].split("&channelCode=")[0]
        );
        sessionStorage.setItem(
          "channelCode",
          window.location.href.split("&channelCode=")[1]
        );
      }
    }
    getMenu({ productCode: "PC_DTY6UHJPUBG8",channelCode:channelCode}).then((res) => {
      this.num = res.data.firstList.length;
      this.menuData = res.data.firstList;
      this.centerImg = res.data.lastList;
      this.titlePictureUrl = res.data.titlePictureUrl;
      this.titlePicture = res.data.titlePicture
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "login") {
        setTimeout(() => {
          window.location.href = window.location.href;
        }, 500);
      }
    });
  },
  methods: {
    //头图下面的tap
    menuDataBtn(item) {
      if (item.label == "体检预约(废弃)") {
        window.location.href =
          "https://mobile.touchealth.com/common/login?pageId=1CblVfALsRj&channelNo=CH18392000&rentId=1621336003874783233";
      } else if (item.label == "体检预约") {
        if (localStorage.getItem("token")) {
          this.$router.push({ name: "richHome" });
        } else {
          Notify({ type: "warning", message: "请先登录" });
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 800);
        }
        // this.$router.push({
        //   // name: "teamCustomized",
        // });
      } else if (item.label == "报告解读") {
        if (localStorage.getItem("token")) {
          this.$router.push({ name: "reportInterpretation" });
        } else {
          Notify({ type: "warning", message: "请先登录" });
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 800);
        }
      } else if (item.label == "普惠好药") {
        if (localStorage.getItem("token")) {
          quickLogin().then((res) => {
            window.location.href = res.data.data.url;
          });
        } else {
          Notify({ type: "warning", message: "请先登录" });
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 800);
        }
      } else {
        this.$router.push({
          name: "blankSpace",
        });
      }
    },
    //头图
    imgUrlBtn() {
      if (localStorage.getItem("token")) {
        window.location.href =
          this.titlePictureUrl + "&token=" + localStorage.getItem("token");
      } else {
        Notify({ type: "warning", message: "请先登录" });
        setTimeout(() => {
          this.$router.push({ name: "login" });
        }, 800);
      }
    },
    //产品推文
    productTweetsBtn(item) {
      this.$router.push({
        name: "home/productTweets",
        query: { imageUrl: item.imageUrl },
      });
    },
    //产品推荐
    imgBtn(item) {
      if(item.type === '1'){
        window.location.href= "https://service.mdcare.com.cn/"
        return
      }
      this.$router.push({
        name: "home/productRecommendations",
        query: { type: item.type },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.displayBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  width: 100%;
  height: 100vh;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

.shopBox {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid #ccc;
}

.shopFirstBox {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shopFirstBox > view {
  width: 50px;
  margin-right: 10px;
}

.shopLastBox {
  width: 100px;
  text-align: right;
}

.van-search__content--round {
  background-color: #fff !important;
}

.tabBox {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 15px;
}
.recommendBox {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.textTitle {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
  display: block;
}
</style>
