<template>
  <div class="root">
    <div class="head">
      <p>预约</p>
    </div>
    <div class="form">
      <van-form>
        <van-field v-model="fieldValue" is-link readonly label="套餐和机构" placeholder="请选择套餐和机构" @click="show = true" />
        <van-popup v-model="show" round position="bottom">
          <van-cascader v-model="cascaderValue" title="请选择套餐和机构" :options="options" @close="show = false"
            @finish="onFinish" />
        </van-popup>
        <van-field readonly clickable name="picker" :value="value" label="体检人员" placeholder="点击选择体检人员"
          @click="showPicker = true" />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
        </van-popup>
        <van-field v-model="bookDateValue" is-link readonly label="预约日期" placeholder="请选择预约日期" @click="clickBookDate" />
        <van-popup v-model="bookDateShow" round position="bottom">
          <van-cascader v-model="bookDateCascaderValue" title="请选择预约日期" :options="bookDateOptions" @close="show = false"
            @finish="onBookDateFinish" />
        </van-popup>
      </van-form>
      <div class="submit" @click="bookBtn">
        <p>确定</p>
      </div>
    </div>
    <!-- 遮罩层 -->
    <van-overlay :show="overlayShow">
      <div class="wrapper">
        <div class="block">
          <van-circle :color="gradientColor" v-model="currentRate" :rate="30" :speed="30" size="150px" text="正在加载...">
          </van-circle>
        </div>
      </div>
    </van-overlay>
    <!-- 遮罩层 -->
    <van-overlay :show="overlayShow2">
      <div class="wrapper">
        <div class="block">
          <van-loading type="spinner" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { getMenu } from "@/api/api";
import { Notify, Dialog } from "vant";
import { getCardInfoById, QueryUserInfo, GetCapacityOfInstitution, MedicalBooking, queryCheckUserExist } from "@/api/richApi";
export default {
  data() {
    return {
      currentRate: 0,
      gradientColor: {
        '0%': '#3fecff',
        '100%': '#6149f6',
      },
      overlayShow: true,
      overlayShow2: false,
      cardInfo: {},
      cardId: null,
      CheckVCardNo: '',
      OriPwd: '',
      show: false,
      fieldValue: '',
      cascaderValue: '',
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [],
      PackageId: '',
      PackageName: '',
      //时间段编号，容量接口中获取，必填
      TimePeriodCode: '',
      InstitutionCode: '',
      value: '',
      columns: [],
      showPicker: false,
      //预约日期
      bookDateValue: '',
      bookDateShow: false,
      bookDateCascaderValue: '',
      bookDateOptions: [],
      //预约日期
      ResvDate: '',
      userId: '',
      //预约时间段
      RequestTimeSpan: '',
      //机构名称
      InstitutionName: ''
    };
  },
  async mounted() {
    await this.getCardId()
    this.currentRate += 20
    await this.getCardInfo()
    this.currentRate += 20
    await this.getCheckUser()
    this.currentRate += 20
    this.checkUserExists();
  },
  methods: {
    clickBookDate() {

    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
      this.InstitutionCode = this.cascaderValue;
      this.InstitutionName = selectedOptions[1].text
      this.PackageId = selectedOptions[0].value
      this.PackageName = selectedOptions[0].text
      this.GetCapacity()
    },
    onBookDateFinish({ selectedOptions }) {
      this.bookDateShow = false;
      this.bookDateValue = selectedOptions.map((option) => option.text).join('/');
      this.TimePeriodCode = selectedOptions[1].value
      this.RequestTimeSpan = selectedOptions[1].text
      this.ResvDate = selectedOptions[0].text
    },
    getCardId() {
      this.cardId = this.$route.query.id
    },
    //获取卡片信息
    async getCardInfo() {
      let res = await getCardInfoById({ id: this.cardId })
      if (res.data.code == '00000') {
        this.options = res.data.data.packageInsOptionsList
        this.cardInfo = res.data.data
        this.CheckVCardNo = res.data.data.virtualCardNo
        this.OriPwd = res.data.data.oriPwd
      }
    },
    //获取体检用户信息
    async getCheckUser() {
      let res = await QueryUserInfo({})
      if (res.data.code == '00000') {
        let data = res.data.data;
        let array = []
        data.forEach(e => {
          let obj = {}
          obj.text = e.name
          obj.value = e.userId
          array.push(obj)
        });
        this.columns = array;
      }
    },
    //获取机构容量
    async GetCapacity() {
      let res = await GetCapacityOfInstitution({
        InstitutionCode: this.InstitutionCode,
        PackageId: this.PackageId,
        IsVIP: this.cardInfo.vip
      });
      if (res.data.code == '00000') {
        this.bookDateOptions = res.data.data
      }
    },
    onConfirm(value) {
      this.userId = value.value
      this.value = value.text;
      this.showPicker = false;
    },
    //点击选择预约日期的时候
    clickBookDate() {
      if (this.bookDateOptions.length == 0) {
        Notify({ type: "warning", message: '请先选择套餐和机构' });
        return;
      } else {
        this.bookDateShow = true
      }
    },
    //提交预约
    async bookBtn() {
      this.overlayShow2 = true
      let res = MedicalBooking(
        {
          InstitutionCode: this.InstitutionCode,
          InstitutionName: this.InstitutionName,
          CheckVCardNo: this.CheckVCardNo,
          OriPwd: this.OriPwd,
          PackageId: this.PackageId,
          PackageName: this.PackageName,
          TimePeriodCode: this.TimePeriodCode,
          UserId: this.userId,
          ResvDate: this.ResvDate,
          CheckCardNo: this.CheckVCardNo,
          RequestTimeSpan: this.RequestTimeSpan
        }
      ).then((res) => {
        console.log(res);
        if (res.data.code == '00000') {
          this.overlayShow2 = false
          Notify({ type: "success", message: '预约成功' });
          this.$router.replace({
            name: 'myCheck'
          })
        } else {
          this.overlayShow2 = false
          Notify({ type: "warning", message: res.data.msg });
        }
      })
    },
    async checkUserExists() {
      let res = await queryCheckUserExist()
      if (res.data.code == '00000') {
        if (res.data.data) {
          //不存在体检人
          Dialog.confirm({
            title: '提示',
            message: '您还未添加体检人员信息，请在 【预约信息管理】 中新增体检人员，点击【确认】按钮跳转到【预约信息管理】',
          })
            .then(() => {
              this.$router.push({
                name: 'bookUserInfo'
              })
            })
            .catch(() => {
              // on cancel
            });
        }
      }
    }
  },
  watch: {
    //监视进度条进度
    currentRate(val1, val2) {
      if (val1 == 70) {
        this.overlayShow = false
      }
    }
  }
};
</script>
<style lang="less" scoped>
*{
  margin: 0;
}
.root {
  background-color: #F1F4FB;
  height: 100vh;

  .head {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .form {
    background-color: #fff;
    padding: 1rem;

    .submit {
      height: 2.5rem;
      width: 100%;
      background: linear-gradient(to bottom, rgb(124, 120, 241), rgb(71, 116, 227));
      border-radius: 100px;
      margin: 0 auto;
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  // width: 120px;
  // height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>