<template>
  <!-- 普惠好药领取权益 -->
  <div class="container">
    <br />
    <br />
    <br />
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="sn"
          name="卡号SN"
          label="卡号SN:"
          :rules="[{ required: true, message: '请填写卡号SN' }]"
        />
        <van-popover v-model:show="showPopover1" class="iconPas1" placement="top-end">
          <div style="padding: 10px;font-size:14px;">请查看权益卡背部</div>
          <template #reference>
            <van-icon name="question-o" @click="iconBtn1" color="#3830FC" size="20" />
          </template>
        </van-popover>
        <!-- <br /> -->
        <!-- <van-field
          v-model="channelCode"
          type="channelCode"
          name="渠道码"
          label="渠道码:"
          :rules="[{ required: true, message: '请填写渠道码' }]"
        />
        <van-popover v-model:show="showPopover2" class="iconPas2" placement="top-end">
          <div style="padding: 10px;font-size:14px;">请从发卡人处得知</div>
          <template #reference>
            <van-icon name="question-o" @click="iconBtn2" color="#3830FC" size="20" />
          </template>
        </van-popover> -->
      </van-cell-group>
      <br />
      <div style="margin: 16px;">
        <van-button class="btn" block native-type="submit">确认激活</van-button>
      </div>
    </van-form>
  </div>
</template>
  <script>
import { Notify } from "vant";
import { ref } from "vue";
import { Dialog } from "vant";
import { binding } from "@/api/api";
export default {
  data() {
    return {
      sn: "",
      channelCode: "",
      showPopover1: false,
      showPopover2: false
    };
  },
  methods: {
    iconBtn1() {
      this.showPopover1 = true;
    },
    iconBtn2() {
      this.showPopover2 = true;
    },
    onSubmit(values) {
      binding({ sn: this.sn }).then(res => {
        if (res.data.code == "00000") {
          Notify({ type: "success", message: res.data.msg });
          setTimeout(() => {
            this.$router.push({ name: "mergePage" });
          }, 500);
        } else {
          Notify({ type: "danger", message: res.data.msg });
        }
      });
    }
  }
};
</script>
  <style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0px;
  display: inline-block;
  text-align: center;
}
.iconPas1 {
  position: absolute;
  top: 17px;
  right: 3%;
}
.iconPas2 {
  position: absolute;
  top: 8.4rem;
  right: 3%;
}
.van-cell {
  position: relative;
  background-color: transparent;
  height: 100px;
}
.van-cell-group {
  background-color: transparent;
}
/deep/.van-field__control {
  background-color: #fff !important;
  height: 35px;
  width: 90%;
}
/deep/.van-field__label {
  text-align: right;
  font-size: 16px;
  width: 4rem;
  margin-top: 6px;
  font-weight: bold;
}
.btn {
  background-image: linear-gradient(to right, #8a79f3, #3830fc);
  color: white;
  font-size: 16px;
  width: 90%;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
}
</style>