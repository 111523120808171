<template>
  <!-- 我的页面健康权益成员 -->
  <div class="container">
    <div style="flex: 1; overflow: auto">
      <van-swipe-cell
        v-for="(item, index) in familyInfo"
        :key="index"
        :dataInfo="item"
        style="margin: 15px 0; border-radius: 10px"
      >
        <van-cell :border="false" @click="checkBtn(item)">
          <div class="display" style="height: 60px">
            <div>
              <div style="font-size: 16px; font-weight: bold; margin-bottom: 5px">{{ item.name }}</div>
              <div>
                {{ item.gender }}
                <span style="margin-left: 10px">{{ item.age }}岁</span>
              </div>
            </div>
            <div
              style="
                flex: 1;
                text-align: left;
                color: #666;
                font-weight: bold;
                font-size: 12px;
                margin-left: 10%;
              "
              v-if="item.isDefault == true"
            >默认就诊人</div>
            <div>
              <van-tag type="primary">{{ item.relationType }}</van-tag>
            </div>
          </div>
        </van-cell>
      </van-swipe-cell>
    </div>
    <div style="height: 55px">
      <van-button type="info" round block color="#3e77db" @click="addBtn"
        >添加</van-button
      >
    </div>
  </div>
</template>
<script>
import { Notify } from "vant";
import { Dialog } from "vant";
import { Toast } from "vant";
import { healthInfoQueryEquity, getFamilyInfoOne } from "@/api/api";
export default {
  data() {
    return {
      familyInfo: []
    };
  },
  mounted() {
    healthInfoQueryEquity().then(res => {
      this.familyInfo = res.data.data;
      if (res.data.data == "") {
        Notify({ type: "primary", message: "暂无成员，请添加" });
      }
    });
  },
  methods: {
    //添加
    addBtn(item) {
      this.$router.push({ name: "mine/familyInfo/familyInfoAddEdit" });
    },
    //点击单个
    checkBtn(item) {
      getFamilyInfoOne({ id: item.id }).then(res => {
        if (res.data.data.productInfo == null) {
          Toast("暂无权益");
        } else {
          this.$router.push({
            name: "mine/equityPersonnel/equityPersonnelDetails",
            query: { id: item.id }
          });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0px;
  background: url("http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E5%81%A5%E5%BA%B7%E6%A1%A3%E6%A1%88%E6%88%90%E5%91%98%E8%83%8C%E6%99%AF%E5%9B%BE.png")
    #f1f1f1 no-repeat;
  background-size: 85% auto;
  background-position: center 30%;
  background-attachment: fixed;
}

.display {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>