<template>
  <div class="home">
    <div class="center">
      <img
        src="http://tech001.oss-cn-hangzhou.aliyuncs.com/H5%E5%95%86%E5%93%81/%E5%9B%A2%E6%A3%80%E5%AE%9A%E5%88%B6.jpg"
        style="width: 100%; height: auto; display: flex; margin-bottom: 5px"
      />
    </div>
    <div class="footer">
      <van-button
        @click="serviceBtn"
        block
        round
        color="linear-gradient(to top, #c7d4eb, #3e77db)"
      >
        联系客服<van-icon name="arrow" style="margin-left: 10px" />
      </van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    serviceBtn() {
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfc964776c9b0909cb6";
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  .center {
    flex: 1;
    overflow: auto;
  }
  .footer {
    width: 100%;
    height: 80px;
    display: flex;
    box-shadow: 0px 0px 10px 0px #3e77db;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;
  }
}
</style>