<template>
  <!-- 我的订单 -->
  <div class="home">
    <van-tabs v-model="active" color="#3e77db" sticky @click="onClick">
      <van-tab title="全部订单" style="padding: 0 10px">
        <div class="centerBox" v-for="(item, index) in data" :key="index">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div style="width: 28%">
              <img
                :src="item.pictureUrl"
                alt=""
                style="width: 100%; height: auto; display: block"
              />
            </div>
            <div style="flex: 1; margin-left: 1rem">
              <ul style="font-weight: bold">
                <li>{{ item.productName }}</li>
                <li style="margin: 5px 0">下单时间：{{ item.createTime }}</li>
                <li style="color: red">
                  ￥<span style="font-size: 16px">{{ item.amount }}</span
                  >元
                </li>
              </ul>
            </div>
          </div>
          <div style="text-align: right; margin-top: 10px">
            <div v-if="item.orderStatus == '待付款'">
              <van-button
                round
                hairline
                size="small"
                type="danger"
                color="#666"
                plain
                style="margin-right: 10px"
                @click="cancelOrder(item)"
                ><span style="padding: 5px; box-sizing: border-box"
                  >取消订单</span
                ></van-button
              >
              <van-button
                round
                hairline
                size="small"
                type="primary"
                color="#3e77db"
                @click="goPay(item)"
                ><span style="padding: 5px; box-sizing: border-box"
                  >待付款</span
                ></van-button
              >
            </div>
            <div v-if="item.orderStatus == '已付款'">
              <p style="color: #3e77db; margin-right: 10px">已完成</p>
            </div>
            <div v-if="item.orderStatus == '已取消'">
              <p style="color: #666; margin-right: 10px">已取消</p>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="待付款" style="padding: 0 10px">
        <div class="centerBox" v-for="(item, index) in data" :key="index">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div style="width: 28%">
              <img
                :src="item.pictureUrl"
                alt=""
                style="width: 100%; height: auto; display: block"
              />
            </div>
            <div style="flex: 1; margin-left: 1rem">
              <ul style="font-weight: bold">
                <li>{{ item.productName }}</li>
                <li style="margin: 5px 0">下单时间：{{ item.createTime }}</li>
                <li style="color: red">
                  ￥<span style="font-size: 16px">{{ item.amount }}</span
                  >元
                </li>
              </ul>
            </div>
          </div>
          <div style="text-align: right; margin-top: 10px">
            <div v-if="item.orderStatus == '待付款'">
              <van-button
                round
                hairline
                size="small"
                type="danger"
                color="#666"
                plain
                style="margin-right: 10px"
                @click="cancelOrder(item)"
                ><span style="padding: 5px; box-sizing: border-box"
                  >取消订单</span
                ></van-button
              >
              <van-button
                round
                hairline
                size="small"
                type="primary"
                color="#3e77db"
                @click="goPay(item)"
                ><span style="padding: 5px; box-sizing: border-box"
                  >待付款</span
                ></van-button
              >
            </div>
            <div v-if="item.orderStatus == '已付款'">
              <p style="color: #3e77db; margin-right: 10px">已完成</p>
            </div>
            <div v-if="item.orderStatus == '已取消'">
              <p style="color: #666; margin-right: 10px">已取消</p>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="已付款" style="padding: 0 10px">
        <div class="centerBox" v-for="(item, index) in data" :key="index">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div style="width: 28%">
              <img
                :src="item.pictureUrl"
                alt=""
                style="width: 100%; height: auto; display: block"
              />
            </div>
            <div style="flex: 1; margin-left: 1rem">
              <ul style="font-weight: bold">
                <li>{{ item.productName }}</li>
                <li style="margin: 5px 0">下单时间：{{ item.createTime }}</li>
                <li style="color: red">
                  ￥<span style="font-size: 16px">{{ item.amount }}</span
                  >元
                </li>
              </ul>
            </div>
          </div>
          <div style="text-align: right; margin-top: 10px">
            <div v-if="item.orderStatus == '待付款'">
              <van-button
                round
                hairline
                size="small"
                type="danger"
                color="#666"
                plain
                style="margin-right: 10px"
                @click="cancelOrder(item)"
                ><span style="padding: 5px; box-sizing: border-box"
                  >取消订单</span
                ></van-button
              >
              <van-button
                round
                hairline
                size="small"
                type="primary"
                color="#3e77db"
                @click="goPay(item)"
                ><span style="padding: 5px; box-sizing: border-box"
                  >待付款</span
                ></van-button
              >
            </div>
            <div v-if="item.orderStatus == '已付款'">
              <p style="color: #3e77db; margin-right: 10px">已完成</p>
            </div>
            <div v-if="item.orderStatus == '已取消'">
              <p style="color: #666; margin-right: 10px">已取消</p>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <div style="height: 2rem"></div>
  </div>
</template>
<script>
import { myOrderQuery, cancelOrder, orderPay } from "@/api/api";
import { Dialog } from "vant";
import { Notify } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      active: 0,
      data: [],
      isTrue: false,
    };
  },
  mounted() {
    myOrderQuery({ orderStatus: 0 }).then((res) => {
      this.data = res.data.data;
    });
  },
  methods: {
    onClick(name, title) {
      myOrderQuery({ orderStatus: name }).then((res) => {
        this.data = res.data.data;
      });
    },
    cancelOrder(item) {
      Dialog.confirm({
        message: "确定取消订单吗？",
      })
        .then(() => {
          cancelOrder({ orderId: item.orderId }).then((res) => {
            if (res.data.code == "00000") {
              Notify({ type: "success", message: "取消订单成功" });
              myOrderQuery({ orderStatus: this.active }).then((res) => {
                this.data = res.data.data;
              });
            } else {
              Notify({ type: "danger", message: "取消订单失败" });
            }
          });
        })
        .catch(() => {});
    },
    goPay(item) {
      this.isTrue = true;
      orderPay({ orderId: item.orderId }).then((res) => {
        window.location.href = res.data.data.qrCode;
        this.isTrue = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
}
/deep/.van-tab {
  background: #fff;
  color: #3e77db;
}
.centerBox {
  background: #fff;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;
}
</style>