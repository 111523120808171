<template>
  <div class="root">
    <div class="head">
      <p>常见问题</p>
    </div>
    <div class="content">
      <van-collapse v-model="activeNames" accordion>
        <van-collapse-item title="体检后多久能查询报告 ? " name="1">
          体检后5~7个工作日可查询电子报告。
        </van-collapse-item>
        <van-collapse-item title="何时可以开票?" name="2">
          您可在体检完的当天，门店前台提交【电子发票】(增值税普通发票)申请，内容为:“医疗服务*体检
          费。抬头按您的意愿(公司或个人皆可)。
        </van-collapse-item>
        <van-collapse-item title="如何开票?" name="3">
          小程序预约的钱只能在小程序上开票，其他形式支付的可以在前台开票。小程序开票可以在我的体检
          >查看>开票
        </van-collapse-item>
        <van-collapse-item title="取消预约后，预约时支付的钱如何退还?" name="4">
          未体检，取消预约使自动退还到支出账户。
        </van-collapse-item>
        <van-collapse-item title="体检是否需要空腹?" name="5">
          体检需要空腹，请体检前一天晚上八点后禁食，晚上十点后禁水。体检当天空腹带好体检人身份证
          件，于7:30-10:00间到预约的门店前台进行登记。(10:00前台停止登记接待)
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>
<script>
import { getMenu } from "@/api/api";
export default {
  data() {
    return {
      activeNames: ['1'],
    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0 0;
}

.root {
  background-color: #F1F4FB;
  height: 100vh;

  .head {
    height: 3rem;
    background: linear-gradient(to bottom, rgb(124, 120, 241), rgb(71, 116, 227));
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      text-align: center;
      font-size: 24px;
      color: #fff;
    }
  }
}

/deep/.van-collapse-item {
  .van-cell {
    padding: 0.8rem 0 0.8rem 1rem;
    font-size: 16px;
    font-weight: bold;
  }

  // 右侧箭头的位置
  .van-cell__right-icon {
    margin-right: 0.5rem;
  }
}
</style>