<template>
  <div>
    <img
      :src="imageUrl"
      alt=""
      style="width: 100%; height: auto; display: block"
    />
  </div>
</template>
<script>
import { getMenu } from "@/api/api";
export default {
  data() {
    return {
      imageUrl: "",
    };
  },
  mounted() {
    this.imageUrl = this.$route.query.imageUrl;
  },
  methods: {},
};
</script>