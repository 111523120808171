<template>
  <!-- 健康档案成员 -->
  <van-form validate-first>
    <div class="home">
      <div class="center">
        <div class="formBox">
          <h3 class="title">基本信息</h3>
          <van-field
            v-model="form.name"
            label="姓名"
            name="name"
            placeholder="请输入姓名"
          />
          <van-field name="gender" label="性别">
            <template #input>
              <van-radio-group v-model="form.gender" direction="horizontal">
                <van-radio name="男">男</van-radio>
                <van-radio name="女">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="form.age"
            label="年龄"
            name="age"
            placeholder="请输入年龄"
          />
          <van-field
            readonly
            clickable
            name="relationType"
            :value="form.relationType"
            label="关系"
            placeholder="请选择与账户之间的关系"
            right-icon="arrow"
            @click="relationTypeShow = true"
          />
          <van-popup v-model="relationTypeShow" position="bottom">
            <van-picker
              show-toolbar
              :columns="relationTypeSelect"
              @confirm="relationTypeConfirm"
              @cancel="relationTypeShow = false"
            >
              <template #option="option">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <div>{{ option.name }}</div>
                </div>
              </template>
            </van-picker>
          </van-popup>
        </div>
        <div class="formBox">
          <van-field
            v-model="form.phone"
            label="手机号"
            name="phone"
            placeholder="请输入手机号"
          />
          <van-field
            v-model="form.idNo"
            label="身份证"
            name="idNo"
            placeholder="请输入身份证"
          />
        </div>
        <div class="formBox">
          <van-field
            name="isDefault"
            label="设置默认就诊人"
            label-width="130px"
          >
            <template #input>
              <van-switch v-model="form.isDefault" size="20" />
            </template>
          </van-field>
        </div>
      </div>
      <div class="footer">
        <div>
          <van-button
            @click="submitBtn"
            :loading="loading"
            :disabled="loading"
            type="info"
            color="#3e77db"
            round
            block
            style="border-radius: 30px; padding: 0 40px"
            >{{ text }}</van-button
          >
        </div>
      </div>
    </div>
  </van-form>
</template>
  <script>
import { getSelect, getFamilyAddEdit, getFamilyInfoOne } from "@/api/api";
import { Notify } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      loading: false,
      text: "",
      form: {
        id: "",
        name: "",
        idNo: "",
        gender: "",
        phone: "",
        age: "",
        isDefault: false,
        equityCode: "",
        channelCode: "",
      },
      relationTypeSelect: [],
      relationTypeShow: false,
    };
  },
  mounted() {
    this.form.equityCode = sessionStorage.getItem("equityCode");
    this.form.channelCode = sessionStorage.getItem("channelCode");
    if (this.$route.query.id) {
      getFamilyInfoOne({ id: this.$route.query.id }).then((res) => {
        this.form = res.data.data;
      });
      this.text = "更新";
    } else {
      this.text = "提交";
    }
    getSelect().then((res) => {
      this.relationTypeSelect = res.data.data.relationType;
    });
  },
  methods: {
    relationTypeConfirm(value) {
      this.form.relationType = value.name;
      this.relationTypeShow = false;
    },
    submitBtn() {
      const nameCheck =
        /(^[\u4e00-\u9fa5]{1}[\u4e00-\u9fa5\.·。]{0,18}[\u4e00-\u9fa5]{1}$)|(^[a-zA-Z]{1}[a-zA-Z\s]{0,18}[a-zA-Z]{1}$)/;
      const ageCheck = /^(?:[1-9][0-9]?|1[01][0-9]|120)$/;
      const idNoCheck =
        /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      const phoneCheck = /^1[3-9][0-9]{9}$/;
      if (nameCheck.test(this.form.name) == false) {
        Toast("请输入正确的姓名");
      } else if (this.form.gender == "") {
        Toast("请选择性别");
      } else if (ageCheck.test(this.form.age) == false) {
        Toast("请输入正确的年龄");
      } else if (this.form.relationType == undefined) {
        Toast("请选择与账户之间的关系");
      } else if (phoneCheck.test(this.form.phone) == false) {
        Toast("请输入正确的手机号");
      } else if (idNoCheck.test(this.form.idNo) == false) {
        Toast("请输入正确的身份证");
      } else {
        this.loading = true;
        getFamilyAddEdit(this.form).then((res) => {
          if (res.data.code == "00000") {
            Notify({ type: "success", message: this.text + "成功" });
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          } else {
            Notify({ type: "danger", message: res.data.msg });
          }
        });
      }
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  },
};
</script>
  <style lang="less" scoped>
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .center {
    padding: 15px;
    box-sizing: border-box;
    flex: 1;
    overflow: auto;
  }
  .footer {
    width: 100%;
    height: 65px;
    padding: 0 10px;
    box-sizing: border-box;
  }
}
.title {
  padding: 0 10px;
  box-sizing: border-box;
}
.formBox {
  margin: 10px 0;
  border-radius: 10px;
  background: #fff;
  padding: 5px;
  box-sizing: border-box;
}
</style>