import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import loginPage from '../views/loginPage.vue'
import home from '../views/homePage/home.vue'
import product from '../views/productPage/product.vue'
import mine from '../views/minePage/mine.vue'
import productRecommendations from '../views/homePage/productRecommendations.vue'
import productTweets from '../views/homePage/productTweets.vue'
import userAgreement from '../views/component/userAgreement.vue'
import familyInfo from '../views/component/familyInfo.vue'
import familyInfoAddEdit from '../views/component/familyInfoAddEdit.vue'
import healthRecords from '../views/component/healthRecords.vue'
import healthInfo from '../views/component/healthInfo.vue'
import reportHistory from '../views/component/reportHistory.vue'
import outpatientRecord from '../views/component/outpatientRecord.vue'
import buyMedicineHistory from '../views/component/buyMedicineHistory.vue'
import checkHistory from '../views/component/checkHistory.vue'
import historyInfo from '../views/component/historyInfo.vue'
import historyInformation from '../views/component/historyInformation.vue'
import reportHistoryDetail from '../views/component/reportHistoryDetail.vue'
import myOrder from '../views/component/myOrder.vue'
import blankSpace from '../views/component/blankSpace.vue'
import teamCustomized from '../views/component/teamCustomized.vue'
import reportInterpretation from '../views/component/reportInterpretation.vue'
import equityPersonnel from '../views/component/equityPersonnel.vue'
import equityPersonnelDetails from '../views/component/equityPersonnelDetails.vue'
import memberPurchase from '../views/component/memberPurchase.vue'
import purchaseMembership from '../views/component/purchaseMembership.vue'
import claimAnInterest from '../views/component/claimAnInterest.vue'
import myCard from '../views/cardPage/saleCard.vue'
import bookHome from '@/views/accountBook/bookHome.vue'
import bookLogin from '@/views/accountBook/bookLogin.vue'
import orderView from '@/views/accountBook/orderView.vue'
import orderDetail from '@/views/accountBook/orderDetail.vue'
import policyFile from '@/views/accountBook/policyFile.vue'
import richHome from '@/views/rich/richHome.vue'
import myCheck from '@/views/rich/myCheck.vue'
import institutionView from '@/views/rich/institutionView.vue'
import institutionDetail from '@/views/rich/institutionDetail.vue'
import bookCheck from '@/views/rich/bookCheck.vue'
import bookUserInfo from '@/views/rich/bookUserInfo.vue'
import bookUserInfoEdit from '@/views/rich/bookUserInfoEdit.vue'
import viewBookForm from '@/views/rich/viewBookForm.vue'
import checkReport from '@/views/rich/checkReport.vue'
import myReport from '@/views/rich/myReport.vue'
import richQuestions from '@/views/rich/questions.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'mergePage',
    component: home,
    meta: {
      keepalive: true
    },
  },
  {
    path: '/blankSpace',
    name: 'blankSpace',
    component: blankSpace,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/teamCustomized',
    name: 'teamCustomized',
    component: teamCustomized,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/reportInterpretation',
    name: 'reportInterpretation',
    component: reportInterpretation,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/productRecommendations',
    name: 'home/productRecommendations',
    component: productRecommendations,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/productTweets',
    name: 'home/productTweets',
    component: productTweets,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/purchaseMembership',
    name: 'home/purchaseMembership',
    component: purchaseMembership,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/home/claimAnInterest',
    name: 'home/claimAnInterest',
    component: claimAnInterest,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/login/userAgreement',
    name: 'login/userAgreement',
    component: userAgreement,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/loginPage',
    name: 'loginPage',
    component: loginPage,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/product',
    name: 'product',
    component: product,
    meta: {
      keepalive: true
    },
  },
  {
    path: '/mine',
    name: 'mine',
    component: mine,
    meta: {
      keepalive: true
    },
  },
  {
    path: 'myCard',
    name: 'myCard',
    component: myCard,
    meta: {
      keepalive: false
    }
  },
  {
    path: '/mine/familyInfo',
    name: 'mine/familyInfo',
    component: familyInfo,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/mine/equityPersonnel',
    name: 'mine/equityPersonnel',
    component: equityPersonnel,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/mine/equityPersonnel/equityPersonnelDetails',
    name: 'mine/equityPersonnel/equityPersonnelDetails',
    component: equityPersonnelDetails,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/mine/familyInfo/familyInfoAddEdit',
    name: 'mine/familyInfo/familyInfoAddEdit',
    component: familyInfoAddEdit,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/mine/healthRecords',
    name: 'mine/healthRecords',
    component: healthRecords,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/mine/healthRecords/healthInfo',
    name: 'mine/healthRecords/healthInfo',
    component: healthInfo,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/mine/reportHistory',
    name: 'mine/reportHistory',
    component: reportHistory,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/mine/healthRecords/outpatientRecord',
    name: 'mine/healthRecords/outpatientRecord',
    component: outpatientRecord,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/mine/healthRecords/buyMedicineHistory',
    name: 'mine/healthRecords/buyMedicineHistory',
    component: buyMedicineHistory,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/mine/healthRecords/checkHistory',
    name: 'mine/healthRecords/checkHistory',
    component: checkHistory,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/mine/healthRecords/historyInfo',
    name: 'mine/healthRecords/historyInfo',
    component: historyInfo,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/mine/healthRecords/historyInfo/historyInformation',
    name: 'mine/healthRecords/historyInfo/historyInformation',
    component: historyInformation,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/mine/reportHistory/reportHistoryDetail',
    name: 'mine/reportHistory/reportHistoryDetail',
    component: reportHistoryDetail,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/mine/myOrder',
    name: 'mine/myOrder',
    component: myOrder,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/mine/memberPurchase',
    name: 'mine/memberPurchase',
    component: memberPurchase,
    meta: {
      keepalive: false
    },
  },
  {
    path: '/book/bookHome',
    name: 'bookHome',
    component: bookHome,
  },
  {
    path: '/book/bookLogin',
    name: 'bookLogin',
    component: bookLogin,
  },
  {
    path: '/book/orderView',
    name: 'orderView',
    component: orderView,
  },
  {
    path: '/book/orderDetail',
    name: 'orderDetail',
    component: orderDetail,
  },
  {
    path: '/book/policyFile',
    name: 'policyFile',
    component: policyFile,
  },
  {
    path: '/rich/richHome',
    name: 'richHome',
    component: richHome,
  },
  {
    path: '/rich/myCheck',
    name: 'myCheck',
    component: myCheck,
  },
  {
    path: '/rich/institutionView',
    name: 'institutionView',
    component: institutionView,
  },
  {
    path: '/rich/institutionDetail',
    name: 'institutionDetail',
    component: institutionDetail,
  },
  {
    path: '/rich/bookCheck',
    name: 'bookCheck',
    component: bookCheck,
  },
  {
    path: '/rich/bookUserInfo',
    name: 'bookUserInfo',
    component: bookUserInfo,
  },
  {
    path: '/rich/bookUserInfoEdit',
    name: 'bookUserInfoEdit',
    component: bookUserInfoEdit,
  },
  {
    path: '/rich/viewBookForm',
    name: 'viewBookForm',
    component: viewBookForm,
  },
  {
    path: '/rich/checkReport',
    name: 'checkReport',
    component: checkReport,
  },
  {
    path: '/rich/myReport',
    name: 'myReport',
    component: myReport,
  },
  {
    path: '/rich/questions',
    name: 'richQuestions',
    component: richQuestions,
  },
]
  

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   let time = parseInt(new Date().getTime() / 1000) + '';
//   if (Object.keys(to.query).length) {
//     next();
//   } else {
//     let toQuery = JSON.parse(JSON.stringify({ characteristic: time }));
//     next({ path: to.path, query: toQuery });
//   }
// });
// 全局守卫：登录拦截 本地没有存token,请重新登录
router.beforeEach((to, from, next) => {
  let bookToken = localStorage.getItem('bookToken')
  const isBookRoute = to.path.startsWith('/book')
  if (to.name == 'bookLogin') {
    if (bookToken) {
      next({ name: 'bookHome' })
    } else {
      next()
    }
  }
  if (isBookRoute) {
    if (bookToken) {
      next()
    } else {
      next({ name: 'bookLogin' })
    }
  }
  next()
});
export default router