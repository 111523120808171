<template>
  <div class="root">
    <div class="card" v-for="item in orderViewData" :key="item.productType">
      <div class="left">
        <p class="type">产品类型：{{ item.productName }}</p>
        <p class="amount">总成交金额：{{item.totalAmount}}元</p>
        <p class="num">总订单数量：{{item.totalOrderNum}}个</p>
      </div>
      <div class="right" @click="orderDetail(item.productType)">
        <p>详情</p>
      </div>
    </div>
  </div>
</template>
<script>
import { queryOrderView } from '@/api/api'
export default {
  data() {
    return {
      salesCode:'',
      orderViewData:[]
    }
  },
  methods: {
    async orderView(){
      let res = await queryOrderView({salesCode:this.salesCode})
      if(res.data.success){
        this.orderViewData = res.data.data
      }
    },
    orderDetail(productType){
      this.$router.push({
        name:'orderDetail',
        query:{
          productType,
          salesCode:this.salesCode
        }
      })
    }
  },
  mounted() {
    this.salesCode = this.$route.query.salesCode
    this.orderView()
  },
}
</script>
<style lang="less" scoped>
.root{
  padding-top: 20px;
  background-color: rgb(241,244,251);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .card{
    display: flex;
    background-color: #fff;
    width: 95%;
    height: 130px;
    align-items: center;
    justify-content: space-around;
    gap: 100px;
    border-radius: 5px;
    padding-left: 10px;
    .left{
      width: 47%;
      .type{
        font-size: 18px;
        font-weight: bold;
      }
      .amount,.num{
        font-size: 15px;
      }
    }
    .right{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 999em;
      height: 30px;
      width: 80px;
      background-color: rgb(70,115,230);
      p{
        color: #fff;
      }
    }
  }
}
</style>