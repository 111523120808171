import axios from 'axios'
const ConfigBaseURL = '/mergePageBack'
const token = localStorage.getItem('token')
const service = axios.create({
    timeout: 100000,
    baseURL: ConfigBaseURL,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token, //请求头
    }
})
// 添加请求拦截器
service.interceptors.request.use(req=>{
    if(localStorage.getItem('channelCode')){
        req.data['channelCode'] = localStorage.getItem('channelCode')
    }
    if (req.url.startsWith('/merge/sales')) {
        // 从本地存储中获取 token
        const token = localStorage.getItem('bookToken') || sessionStorage.getItem('bookToken');
        if (token) {
          // 如果存在 token，则将其添加到请求头的 Authorization 字段
          req.headers.Authorization = `${token}`;
        }
      }    
    return req;
},err =>{
    return Promise.reject(err)
});

export default service;