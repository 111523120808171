<template>
  <!-- 历史记录单人详情页面 -->
  <div class="home">
    <div class="center">
      <div class="box">
        <h3 class="text">基本信息</h3>
        <van-cell-group v-if="dataInfo.recordType == 1">
          <van-cell title="就诊人" :value="dataInfo.name" />
          <van-cell title="就诊时间" :value="dataInfo.visitTime" />
          <van-cell title="医院" :value="dataInfo.hospital" />
          <van-cell title="科室" :value="dataInfo.dentalDepartment" />
          <van-cell title="医生姓名" :value="dataInfo.doctorName" />
          <van-cell title="诊断" :value="dataInfo.diagnose" />
          <van-cell title="是否需要复诊" :value="dataInfo.needReturnVisit" />
          <van-cell title="病情描述" :value="dataInfo.patientDesc" />
        </van-cell-group>
        <van-cell-group v-if="dataInfo.recordType == 2">
          <van-cell title="就诊人" :value="dataInfo.name" />
          <van-cell title="就诊时间" :value="dataInfo.visitTime" />
          <van-cell title="购药机构" :value="dataInfo.pharmacy" />
          <van-cell title="购药名称" :value="dataInfo.medicineName" />
          <van-cell title="购药数量" :value="dataInfo.medicineNum" />
        </van-cell-group>
        <van-cell-group v-if="dataInfo.recordType == 3">
          <van-cell title="就诊人" :value="dataInfo.name" />
          <van-cell title="就诊时间" :value="dataInfo.visitTime" />
          <van-cell title="体检机构" :value="dataInfo.checkupOrg" />
          <van-cell title="报告名称" :value="dataInfo.checkupReport" />
        </van-cell-group>
      </div>
      <div class="box" v-if="fileList != null">
        <h3 class="text">图片</h3>
        <van-image
          v-for="(item, index) in this.fileList"
          :key="index"
          width="77"
          height="77"
          fit="contain"
          :src="item"
          style="margin: 3px"
          @click="imageBtn(index)"
        />
      </div>
    </div>
    <div class="footer">
      <div>
        <van-button
          @click="submitBtn"
          type="info"
          color="#3e77db"
          round
          block
          style="border-radius: 30px; padding: 0 10px"
          >返回</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { historyInformation } from "@/api/api";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      show: false,
      image: "",
      dataInfo: {},
      fileList: [],
    };
  },
  mounted() {
    const id = location.href.split("id=")[1];
    historyInformation({ id: id }).then((res) => {
      this.dataInfo = res.data.data.dataInfo;
      this.fileList = res.data.data.fileList;
    });
  },
  methods: {
    imageBtn(index) {
      ImagePreview({
        images: this.fileList,
        startPosition: index,
        closeable: true,
      });
    },
    submitBtn() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  .center {
    flex: 1;
    overflow: auto;
    .box {
      border-radius: 10px;
      background: #fff;
      margin: 10px 0;
      padding: 10px;
      box-sizing: border-box;
    }
  }
  .footer {
    width: 100%;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
  }
}
.text {
  padding: 0 10px;
  box-sizing: border-box;
}
</style>