<template>
  <div class="root">
    <div id="mapDiv"></div>
    <div class="operator">
      <!-- <input type="button" @click="map.zoomIn()" value="放大地图" /> -->
      <!-- <input type="button" @click="map.zoomOut()" value="缩小地图" /> -->
      <!-- <input type="button" @click="move" value="平移地图" /> -->
      <div class="back"  @click="move">
        <p>重新定位</p>
      </div>
    </div>
  </div>
</template>

<script>
import MapInit from '@/utils/MapInit.js';

export default {
  props: ['position'],
  data() {
    return {
      T: null,
      map: null,
      zoom: 11,
      localsearch: null,
      control: null,
      //创建标注对象
      marker: null,
      data_info:[],
      user_addr: [],
    }
  },
  methods: {
    //地图初始化
    roadMap() {
      MapInit.init().then(
        T => {
          this.T = T;
          //初始化地图对象
          this.map = new T.Map('mapDiv');
          //去除logo
          document.getElementsByClassName("tdt-control-copyright tdt-control")[0].style.display = 'none';
          //设置显示地图的中心点和级别
          // this.map.centerAndZoom(new T.LngLat(116.41593, 39.92313), this.zoom);
          this.map.centerAndZoom(new T.LngLat(this.position[0],this.position[1]), this.zoom);
          //创建缩放平移控件对象
          this.control = new this.T.Control.Zoom();
          //添加缩放平移控件
          this.map.addControl(this.control);
          let pointArray = new Array();
          for (var i = 0; i < this.data_info.length; i++) {
            this.marker = new this.T.Marker(new this.T.LngLat(this.data_info[i][0], this.data_info[i][1]));  // 创建标注
            let content = this.data_info[i][2];
            // 将标注添加到地图中
            this.map.addOverLay(this.marker);
            pointArray[i] = new this.T.LngLat(this.data_info[i][0], this.data_info[i][1]);
            let map = this.map;
            this.marker.addEventListener('click', function (e) {
              // alert("marker的位置是" + e.target.getLngLat().lng + "," + e.target.getLngLat().lat);
              let point = e.lnglat;
              this.marker = new T.Marker(point);
              let markerInfoWin = new T.InfoWindow(content, { offset: new T.Point(0, -30) });
              map.openInfoWindow(markerInfoWin, point);
            })
          }
          //让所有点在视野范围内
          this.map.setViewport(pointArray);
          let lo = new T.Geolocation();
          let map = this.map;
          // let marker = this.marker;
          let fn = function (e) {
            if (this.getStatus() == 0) {
              map.centerAndZoom(e.lnglat, 14)
              // alert("获取定位坐标：" + e.lnglat.lat + "," + e.lnglat.lng)
              this.user_addr = [e.lnglat.lng, e.lnglat.lat]
              this.marker = new this.T.Marker(e.lnglat);
              this.map.addOverLay(this.marker);
            }
            if (this.getStatus() == 1) {
              map.centerAndZoom(e.lnglat, e.level)
              // alert("获取定位坐标：" + e.lnglat.lat + "," + e.lnglat.lng)
              this.user_addr = [e.lnglat.lng, e.lnglat.lat]
              this.marker = new this.T.Marker(e.lnglat);
              this.map.addOverLay(this.marker);
            }
          }
          lo.getCurrentPosition(fn);
        }).catch()
    },
    //地图平移
    move() {
      this.roadMap()
      // this.map.panTo(new this.T.LngLat(121.38656718567393, 31.166821289995084,"xxx"));
    },
  },
  mounted() {
    let arr = this.position.split(',')
    console.log(arr);
    this.data_info = [arr]
    this.roadMap()
  }
}
</script>


<style scoped>
#mapDiv {
  width: 90vw;
  height: 35%;
  position: absolute;
}

.operator {
  position: absolute;
  top: 85%;
  left: 35%;
}
.back{
  height: 2rem;
  background: linear-gradient(to bottom, rgb(124, 120, 241), rgb(71, 116, 227));
  border-radius: 100px;
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  p{
    text-align: center;
    line-height: 2rem;
    color: #fff;
  }
}
</style>
