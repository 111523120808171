<template>
  <div id="app">
    <div>
        <router-view />
    </div>
    <van-tabbar v-model="active" v-if="this.$route.meta.keepalive == true">
      <van-tabbar-item to="/">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/product">
        <span>产品</span>
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/mine">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon2.active : icon2.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  components: {},
  name: "App",
  data() {
    return {
      active: 0,
      icon: {
        active:
          "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B51.png",
        inactive:
          "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E9%A6%96%E9%A1%B52.png",
      },
      icon1: {
        active:
          "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BA%A7%E5%93%811.png",
        inactive:
          "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E4%BA%A7%E5%93%812.png",
      },
      icon2: {
        active:
          "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%841.png",
        inactive:
          "http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E6%88%91%E7%9A%842.png",
      },
    };
  },
  watch: {
    $route(to, form) {
      if (this.$route.path == "/") {
        this.active = 0;
      } else if (this.$route.path == "/product") {
        this.active = 1;
      } else if (this.$route.path == "/mine") {
        this.active = 2;
      }
    },
  },
  mounted() {
    console.log(this.$route.meta.keepalive);
  },
  methods: {},
};
</script>
<style lang="less">
body,
html {
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  box-sizing: border-box;
}
#app {
  padding: 0;
  margin: 0;
  font-size: 14px;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 0 !important;
}
</style>
