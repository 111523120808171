import request from "@/utils/request";

// 卡片列表查询
export function queryCardList(data) {
  return request({
      url: '/merge/GiftCard/queryCard',
      method: 'post',
      data
  })
}

//卡片数量查询
export function queryCardCount(data) {
  return request({
      url: '/merge/GiftCard/queryCardNum',
      method: 'post',
      data
  })
}