<template>
  <div>
    <img
      :src="agreement"
      alt=""
      style="width: 100%; height: auto; display: block"
    />
  </div>
</template>
<script>
import { getMenu } from "@/api/api";
export default {
  data() {
    return {
      agreement: "",
    };
  },
  mounted() {
    if (this.$route.query.stust == "1") {
      getMenu({ productCode: "PC_DTY6UHJPUBG8" }).then((res) => {
        this.agreement = res.data.agreementAnother;
      });
    }else if(this.$route.query.stust == "2"){
      getMenu({ productCode: "PC_DTY6UHJPUBG8" }).then((res) => {
        this.agreement = res.data.agreement;
      });
    }else if(this.$route.query.stust == "3"){
      getMenu({ productCode: "PC_DTY6UHJPUBG8" }).then((res) => {
        this.agreement = res.data.serviceAgreement;
      });
    }
  },
  methods: {},
};
</script>