<template>
  <div class="root">
    <!-- :style="{ backgroundImage: `url(${homeBack})` }" -->
    <div class="head">
      <p>我的体检</p>
      <van-divider />
    </div>
    <div class="top">
      <van-tabs @click="onTabClick" background="#fff" color="rgb(83,146,203)" title-inactive-color="rgb(102,102,102)"
        title-active-color="rgb(83,146,203)" line-height="4" v-model="name" :before-change="beforeChange">
        <van-tab color="#fff" v-for="item in cardList" :name="item.name" :title="item.title" ::key="item.id">
          <div class="tabContent">
            <div class="box" v-for="item in thRichCardInfoList" v-if="thRichCardInfoList.length != 0">
              <div class="info">
                <p>卡号：{{ item.cardNo }}</p>
                <p>状态：{{ item.statusName }}</p>
              </div>
              <div class="book"
                @click="item.status == 'N' ? goBook(item) : (item.status == 'U' ? editBook(item) : bookFinish(item))">
                <p>预约管理</p>
              </div>
              <div class="san"></div>
              <div class="text">{{ item.statusName }}</div>
            </div>
            <div class="empty" v-if="thRichCardInfoList.length == 0">
              <img :src="icon.empty">
            </div>
          </div>
          <div class="addCard"  @click="addCard">
            <div class="icon">
              <van-icon name="add-o" size="24px" color="#fff" />
            </div>
            <div class="title">
              <p>添加体检信息</p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!-- 弹出框 -->
    <van-popup v-model="show" closeable round position="bottom" :style="{ height: '50%' }" @close="closePop">
      <div class="main">
        <div class="title">
          <p>激活卡片</p>
        </div>
        <van-form validate-first ref="cardFormRef">
          <van-field v-model="CheckCardNo" name="卡号" placeholder="卡号" :rules="[{ required: true, message: '请填写卡号' }]" />
          <van-field type="password" name="密码" v-model="OriPwd" placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]" />
          <div style="margin: 50px;">
            <van-button round block type="info" @click="addCardSubmit">提交</van-button>
          </div>
        </van-form>
      </div>
    </van-popup>
    <!-- 动作面板 -->
    <van-action-sheet v-model="actionsShow" :actions="actions" @select="onSelect" cancel-text="取消"
      close-on-click-action />
    <!-- 修改预约弹窗 -->
    <van-dialog v-model="editBookShow" title="修改预约" show-cancel-button width="350px" @confirm="editBookSubmit">
      <div class="formOuter">
        <van-form>
          <van-field readonly clickable name="picker" :value="InstitutionName" label="预约机构" placeholder="点击选择机构"
            @click="showPicker = true" />
          <van-field readonly clickable name="calendar" :value="bookDateValue" label="预约日期" placeholder="点击选择日期"
            @click="showCalendar = true" />
        </van-form>
      </div>
    </van-dialog>
    <!-- 修改预约机构选择器 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="insColumns" @confirm="onInsPickerConfirm" @cancel="showPicker = false" />
    </van-popup>
    <!-- 修改预约日期选择器 -->
    <van-calendar v-model="showCalendar" @confirm="onCalendarConfirm" :show-confirm="false" :min-date="minDate"
      :max-date="maxDate" />
      <!-- 遮罩层 -->
    <van-overlay :show="overlayShow">
      <div class="wrapper">
        <div class="block">
          <van-loading type="spinner" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Popup, Notify, Dialog } from 'vant';
import { getMenu } from "@/api/api";
import { getCardInfoByToken, LoginAndGetVCardInfo, CancelBook, ChangeMedicalBooking, getInsListPackageId, GetReportInfo } from '@/api/richApi'
export default {
  data() {
    const today = new Date(); // 获取当前日期
    const tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000)); // 后天
    const minDate = new Date(tomorrow.getTime() + (24 * 60 * 60 * 1000)); // 后天的日期
    const maxDate = new Date(minDate.getTime() + (7 * 24 * 60 * 60 * 1000)); // 后天开始的七天内
    return {
      icon: {},
      overlayShow:false,
      name: '0',
      cardList: [],
      thRichCardInfoList: [],
      show: false,
      //卡号，卡密
      CheckCardNo: '',
      OriPwd: '',
      //表单ref
      cardFormRef: null,
      //预约管理动作面板
      actionsShow: false,
      actions: [],
      //卡片id
      id: '',
      //卡号
      CheckCardNoReq: '',
      //卡密
      OriPwdReq: '',
      editBookShow: false,
      InstitutionName: '',
      InstitutionCode: '',
      insColumns: [
        // {"value":213,"text":'淘宝'},
        // {"value":313,"text":'京东'},
        // {"value":413,"text":'拼多多'},
      ],
      showPicker: false,
      bookDateValue: '',
      showCalendar: false,
      minDate: minDate,
      maxDate: maxDate,
    };
  },
  async mounted() {
    await this.getCardList();
    this.getIcon();
  },
  methods: {
    async getIcon() {
      let res = await getMenu({ productCode: 'PC_CI52Y8XINYQM' })
      this.icon = res.data;
    },
    //关闭弹出窗
    closePop() {
      this.CheckCardNo = ''
      this.OriPwd = ''
    },
    //标签栏单击
    onTabClick(name, title) {
      this.name = name;
    },
    beforeChange(val) {
      const element = this.cardList.find(obj => obj.name == val)
      this.thRichCardInfoList = element.thRichCardInfoList
    },
    async getCardList() {
      let res = await getCardInfoByToken({});
      if (res.data.success) {
        this.cardList = res.data.data
        this.thRichCardInfoList = res.data.data[0].thRichCardInfoList
      }
    },
    addCard() {
      this.show = true
    },
    async addCardSubmit() {
      this.$refs.cardFormRef.validate().then(() => {
        LoginAndGetVCardInfo({
          CheckCardNo: this.CheckCardNo,
          OriPwd: this.OriPwd
        }).then((res) => {
          if (res.data.code == '00000') {
            this.getCardList();
            this.show = false
            this.CheckCardNo = ''
            this.OriPwd = ''
            Notify({ type: "success", message: '卡片激活成功' });
          } else {
            Notify({ type: "warning", message: res.data.msg });
          }
        })
      }).catch(() => {
        return;
      })
    },
    goBook(item) {
      this.actions = [
        { name: '预约', disabled: false },
        { name: '查看预约单', disabled: true },
        // { name: '修改预约', disabled: true },
        { name: '取消预约', disabled: true },
        { name: '获取检查报告', disabled: true },
      ]
      this.id = item.id
      this.CheckCardNoReq = item.cardNo
      this.OriPwdReq = item.oriPwd
      this.actionsShow = true;
    },
    editBook(item) {
      this.actions = [
        { name: '预约', disabled: true },
        { name: '查看预约单', disabled: false },
        // { name: '修改预约', disabled: false },
        { name: '取消预约', disabled: false },
        { name: '获取检查报告', disabled: false },
      ]
      this.id = item.id
      this.CheckCardNoReq = item.cardNo
      this.OriPwdReq = item.oriPwd
      this.actionsShow = true;
    },
    bookFinish(item) {
      this.actions = [
        { name: '预约', disabled: true },
        { name: '查看预约单', disabled: false },
        // { name: '修改预约', disabled: true },
        { name: '取消预约', disabled: true },
        { name: '获取检查报告', disabled: false },
      ]
      this.id = item.id
      this.CheckCardNoReq = item.cardNo
      this.OriPwdReq = item.oriPwd
      this.actionsShow = true;
    },
    //动作面板选择后
    onSelect(val) {
      if (val.name == '预约') {
        this.$router.replace({
          name: 'bookCheck',
          query: {
            id: this.id
          }
        })
      } else if (val.name == '取消预约') {
        Dialog.confirm({
          title: '确定取消预约吗',
          message: '取消后可再次预约',
        })
          .then(() => {
            this.overlayShow = true
            CancelBook({
              CheckCardNo: this.CheckCardNoReq,
              OriPwd: this.OriPwdReq
            }).then((res) => {
              this.getCardList();
              this.overlayShow = false
              Notify({ type: "success", message: res.data.msg });
            })
          })
          .catch(() => {
            Notify({ type: "warning", message: res.data.msg });
          });
      } else if (val.name == '修改预约') {
        this.editBookShow = true
        let res = getInsListPackageId({
          //TODO 无法得到卡片的packageId，因为在预约成功后没有存储卡片对应的packageId
        })
      } else if (val.name == '查看预约单') {
        this.$router.push({
          name: 'viewBookForm',
          query: {
            CheckCardNo: this.CheckCardNoReq
          }
        })
      } else if (val.name == '获取检查报告') {
        GetReportInfo({
          CheckCardNo: this.CheckCardNoReq,
          OriPwd: this.OriPwdReq
        }).then((res) => {
          if (res.data.code == '00000' && res.data.data.IsFinished == '1') {
            this.$router.push({
              name: 'checkReport',
              query: {
                StudyId: res.data.data.StudyId,
                CheckCardNo: this.CheckCardNoReq,
                OriPwd: this.OriPwdReq
              }
            })
          } else {
            Notify({ type: "warning", message: res.data.msg });
          }
        })
      }
    },
    onInsPickerConfirm(value) {
      this.InstitutionName = value.text;
      this.InstitutionCode = value.value
      this.showPicker = false;
    },
    // 修改formatDate函数以返回yyyy-MM-dd格式
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1，并且补零
      const day = String(date.getDate()).padStart(2, '0'); // 补零确保日期始终为两位数
      return `${year}-${month}-${day}`;
    },
    onCalendarConfirm(date) {
      this.bookDateValue = this.formatDate(date);
      this.showCalendar = false;
    },
    //修改确定按钮
    async editBookSubmit() {
      let res = ChangeMedicalBooking({
        InstitutionCode: this.InstitutionCode,
        InstitutionName: this.InstitutionName,
        ResvDate: this.bookDateValue
      })
      if (res.data.code == '00000') {
        this.getCardList();
        Notify({ type: "success", message: res.data.msg });
      } else {
        Notify({ type: "warning", message: res.data.msg });
      }
    }
  },
};
</script>
<style lang="less" scoped>
*,
body {
  // background-color: #F1F4FB;
  margin: 0;
}

.root {
  // background-color: rgb(241, 244, 251);
  // height: 100vh;
  background: #F1F4FB 100% 100%;
  padding-bottom: 10vh;

  .head {
    padding-top: 1rem;
    height: 2rem;
    background-color: #F1F4FB;
    // margin-bottom: 1rem;

    p {
      color: rgb(51, 51, 51);
      font-size: 24px;
      font-weight: bold;
      text-align: center;
    }
  }

  .top {
    background-size: cover;
    // height: 20rem;
    // height: 100vh;
    // box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);

    .van-tabs {
      top: 20px;

      .van-tab__text span {
        // color: #fff;
        // color: #000;
      }
    }

    .tabContent {
      margin-top: 30px;
      margin-left: 20px;
      height: 100vh;
      background: #F1F4FB;

      .box {
        position: relative;
        height: 7rem;
        width: 95%;
        // border: 1px solid gray;
        background-color: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        margin-bottom: 1rem;

        .info {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin-left: 1rem;

          p {
            padding: 1rem;
          }
        }

        .book {
          width: 5rem;
          background-color: rgb(70, 116, 231);
          border-radius: 100px;
          height: 2rem;
          position: absolute;
          right: 1rem;
          bottom: 1rem;

          p {
            color: #fff;
            text-align: center;
            vertical-align: middle;
            line-height: 2rem;
          }
        }

        .san {
          position: absolute;
          top: 0;
          right: 0;
          height: 60px;
          width: 60px;
          border-top-right-radius: 10px;
          //background-color: red;
          background: linear-gradient(135deg, rgb(141, 123, 249), rgb(56, 48, 252));
          /* 紫色渐变 */
          clip-path: polygon(0 0, 100% 100%, 100% 0);
        }

        .text {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 4;
          color: #fff;
          transform: rotate(45deg);
          transform-origin: 50% 50%;
          height: 60px;
          width: 60px;
          font-size: 12px;
          text-align: center;
          line-height: 28px;
          white-space: nowrap;

        }
      }

      .empty {
        img {
          width: 100%;
          margin-top: 5rem
        }
      }
    }

    .addCard {
      width: 60%;
      height: 3rem;
      margin: 0 auto;
      background-color: rgb(70, 116, 231);
      position: fixed;
      top: 90%;
      left: 0;
      right: 0;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .title {
        p {
          color: #fff;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  .main {
    padding: 50px 30px 10px 30px;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;

      p {
        font-size: 22px;
        font-weight: bold;
      }
    }
  }
}

.formOuter {
  height: 7rem;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  // width: 120px;
  // height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>