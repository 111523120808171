import { useIntersectionObserver } from '@vueuse/core'

export const lazy = {
  inserted(el, binding) {
    const { stop } = useIntersectionObserver(
      el,
      ([{ isIntersecting }]) => {
        if (isIntersecting) { //el元素是否在视口内
          el.src = binding.value //是就显示图片
          stop()
        }
      },
    )
  }
}